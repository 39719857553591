import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal, Tabs } from 'antd';
import { HeaderTitleComponent } from '../../../../Components/ContentComponent/HeaderTitleComponent';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ContentComponent } from '../../../../Components/ContentComponent/ContentComponent';
import { Form, FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { validationSchema } from '../NewAwardedPromotion/validationSchema';
import ConfigurationTab from '../NewAwardedPromotion/ConfigurationTab';
import PlayersTab from '../NewAwardedPromotion/PlayersTab';
import GamesTab from '../NewAwardedPromotion/GamesTab';
import {
    cloneCampaign,
    createAwardedPromotion,
    resetNewPromotion,
} from '../../../../redux/actions/backoffice/promotions-actions';
import { useDispatch, useSelector } from 'react-redux';
import {
    campaignHasBeenCreatedData,
    getClonedCampaignCsvData,
    getClonedCampaignData,
} from '../../../../redux/selectors/backoffice/propmotions-selectors';
import Papa from 'papaparse';
import dayjs from 'dayjs';
import { currencyUtils } from '../../../../utils';

const { TabPane } = Tabs;

interface IAwardedCampaignProps {
    campaign_name: string;
    timezone: 'Etc/UTC';
    start_at: string;
    end_at: string;
    number_of_players: number;
    valid_for: number | null;
    strategy: string;
    start_event: string;
    players: string[];
    currency: string;
    subunit: number;
    price: number | null;
    number_of_features: number | null;
    gameIds: string[];
    allowPostExpirationCompletion: boolean;
    rules_url: string;
}

const getInitialValues = (): IAwardedCampaignProps => {
    return {
        campaign_name: '',
        timezone: 'Etc/UTC',
        start_at: '',
        end_at: '',
        number_of_players: 1000,
        valid_for: null,
        strategy: 'FIRST_N_PLAYERS',
        start_event: 'ACCEPT',
        players: [],
        currency: 'USD',
        subunit: 100,
        price: 0,
        number_of_features: null,
        gameIds: [],
        allowPostExpirationCompletion: false,
        rules_url: '',
    };
};

const CloneAwardedPromotion = () => {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const campaignHasBeenCreated = useSelector(campaignHasBeenCreatedData);
    const [discardConfirmVisible, setDiscardConfirmVisible] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [formValues, setFormValues] = useState<any>(null);
    const initialValues = useMemo(() => getInitialValues(), []);
    const clonedCampaign: any = useSelector(getClonedCampaignData);
    const clonedCampaignCsv: any = useSelector(getClonedCampaignCsvData);

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema(t),
        onSubmit: (values) => {
            setFormValues(values);
            setConfirmVisible(true);
        },
    });
    const formikRef = useRef(formik);

    const handleCSVParse = useCallback((csvData: string) => {
        Papa.parse(csvData, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                const playerIds = results.data.map((row: any) => Object.values(row)[0]);

                const currentPlayers = formikRef.current.values.players || [];

                const updatedPlayers = [...currentPlayers, ...playerIds];

                formikRef.current.setFieldValue('players', updatedPlayers);

                formikRef.current.validateField('players');
            },
        });
    }, []);

    useEffect(() => {
        dispatch(cloneCampaign(params.campaignId));
    }, [dispatch, params.campaignId]);

    useEffect(() => {
        formikRef.current = formik;
    }, [formik]);

    useEffect(() => {
        if (clonedCampaign) {
            const newValues: any = {
                campaign_name: `Copy of ${clonedCampaign.name}` || '',
                timezone: clonedCampaign.timezone || 'Etc/UTC',
                number_of_players: clonedCampaign.eligiblePlayersNumber || null,
                valid_for: clonedCampaign.validForDays || null,
                strategy: clonedCampaign.strategy || 'FIRST_N_PLAYERS',
                currency: clonedCampaign.currencyCode || 'USD',
                subunit:
                    currencyUtils.getCurrencyDataByCode(clonedCampaign.currencyCode).subunit || 100,
                price: clonedCampaign.bet || null,
                number_of_features: clonedCampaign.betNumber || null,
                gameIds: clonedCampaign.games.map((game: any) => game.id) || [],
                start_event: clonedCampaign.startEvent || null,
                allowPostExpirationCompletion:
                    clonedCampaign.allowPostExpirationCompletion || false,
            };

            if (JSON.stringify(formikRef.current.values) !== JSON.stringify(newValues)) {
                formikRef.current.setValues(newValues);
            }
        }
    }, [clonedCampaign]);

    useEffect(() => {
        if (clonedCampaignCsv) {
            handleCSVParse(clonedCampaignCsv);
        }
    }, [clonedCampaignCsv, handleCSVParse]);

    useEffect(() => {
        if (campaignHasBeenCreated) {
            navigate('/casino/awarded-promotions');
        }

        return () => {
            dispatch(resetNewPromotion());
        };
    }, [navigate, dispatch, campaignHasBeenCreated]);

    const handleConfirmSubmit = () => {
        dispatch(createAwardedPromotion(formValues));
        setConfirmVisible(false);
    };

    const handleDiscard = () => {
        setDiscardConfirmVisible(true);
    };

    const confirmDiscard = () => {
        formik.resetForm();
        navigate('/casino/awarded-promotions');
        setDiscardConfirmVisible(false);
    };

    const costPerPlayer = useMemo(() => {
        return formik.values.price && formik.values.number_of_features && formik.values.subunit
            ? formik.values.number_of_features * (formik.values.price / formik.values.subunit)
            : 0;
    }, [formik.values.price, formik.values.number_of_features, formik.values.subunit]);

    const totalPlayers = useMemo(() => {
        if (formik.values.strategy === 'FIRST_N_PLAYERS' && formik.values.number_of_players) {
            return formik.values.number_of_players;
        }
        if (formik.values.strategy === 'PLAYER_LIST' && formik.values.players?.length > 0) {
            return formik.values.players.length;
        }
        return 0;
    }, [formik.values.strategy, formik.values.number_of_players, formik.values.players]);

    const calculatedTotalCost = useMemo(
        () => costPerPlayer * totalPlayers,
        [costPerPlayer, totalPlayers],
    );

    const tabHasErrors = (fields: Array<keyof IAwardedCampaignProps>) =>
        fields.some((field) => formik.errors[field] && formik.touched[field]);

    const tabsFields: any = {
        configuration: [
            'campaign_name',
            'timezone',
            'start_at',
            'end_at',
            'valid_for',
            'start_event',
            'allowPostExpirationCompletion',
            'rules_url',
        ],
        players: ['number_of_players', 'strategy', 'players'],
        games: ['currency', 'price', 'number_of_features', 'gameIds'],
    };

    return (
        <ContentComponent
            header={
                <div className="flex justify-between">
                    <HeaderTitleComponent
                        title={'Awarded Feature'}
                        customBreadcrumbs={
                            <Link to={`/casino/awarded-promotions`}>{'Promotions'}</Link>
                        }
                    />
                    <div className="flex items-end">
                        <Button size="large" shape="round" onClick={handleDiscard}>
                            Discard Campaign
                        </Button>
                        <Button
                            danger
                            className="ml-2"
                            size="large"
                            shape="round"
                            onClick={formik.submitForm}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            }
            innerContent={
                <div style={{ padding: 24, backgroundColor: '#fff', borderRadius: 8 }}>
                    <Modal
                        title="Are you sure you want to discard this campaign?"
                        open={discardConfirmVisible}
                        onOk={confirmDiscard}
                        onCancel={() => setDiscardConfirmVisible(false)}
                    />
                    <Modal
                        title="Confirm Promotion Submission"
                        open={confirmVisible}
                        onOk={handleConfirmSubmit}
                        onCancel={() => setConfirmVisible(false)}
                    >
                        {formValues && (
                            <div className="space-y-4 p-4  rounded-lg border-dashed">
                                <div className="flex justify-between">
                                    <span className="font-medium text-gray-700">
                                        Campaign Name:
                                    </span>
                                    <span className="text-gray-900">
                                        {formValues.campaign_name}
                                    </span>
                                </div>
                                <div className="flex justify-between">
                                    <span className="font-medium text-gray-700">Timezone:</span>
                                    <span className="text-gray-900">{formValues.timezone}</span>
                                </div>
                                <div className="flex justify-between">
                                    <span className="font-medium text-gray-700">Start Date:</span>
                                    <span className="text-gray-900">
                                        {dayjs(formValues.start_at).format('HH:mm  MMMM D, YYYY')}
                                    </span>
                                </div>
                                <div className="flex justify-between">
                                    <span className="font-medium text-gray-700">End Date:</span>
                                    <span className="text-gray-900">
                                        {dayjs(formValues.end_at).format('HH:mm MMMM D, YYYY')}
                                    </span>
                                </div>
                                <div className="flex justify-between">
                                    <span className="font-medium text-gray-700">
                                        Number of Players:
                                    </span>
                                    <span className="text-gray-900">{totalPlayers}</span>
                                </div>
                                <div className="flex justify-between">
                                    <span className="text-gray-700 font-bold">Total cost:</span>
                                    <span className="text-gray-900 font-bold">
                                        {calculatedTotalCost} {formValues.currency}
                                    </span>
                                </div>
                            </div>
                        )}
                    </Modal>
                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                            <Tabs defaultActiveKey="1">
                                <TabPane
                                    tab={`Configuration ${tabHasErrors(tabsFields.configuration) ? '!' : ''}`}
                                    key="1"
                                >
                                    <ConfigurationTab />
                                </TabPane>
                                <TabPane
                                    tab={`Players ${tabHasErrors(tabsFields.players) ? '!' : ''}`}
                                    key="2"
                                >
                                    <PlayersTab formik={formik} />
                                </TabPane>
                                <TabPane
                                    tab={`Games ${tabHasErrors(tabsFields.games) ? '!' : ''}`}
                                    key="3"
                                >
                                    <GamesTab formik={formik} />
                                </TabPane>
                            </Tabs>

                            <div style={{ marginTop: 24, textAlign: 'right' }}></div>
                        </Form>
                    </FormikProvider>
                </div>
            }
        />
    );
};

export default CloneAwardedPromotion;
