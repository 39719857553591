import { currencyUtils, dataFormatterUtils, getAbbreviateNumber } from 'utils';

export type chartKey = 'bet' | 'win' | 'net' | 'spins' | 'rtp' | 'newPlayers' | 'players';

interface ITooltipParams {
    seriesName: string;
    value: string;
    marker: string;
}

export class ChartConstants {
    public static tooltipCommonOptions: any = {
        trigger: 'axis',
        padding: 8,
        textStyle: {
            color: '#0B1022',
            fontSize: 12,
        },
        backgroundColor: 'white',
        borderColor: '#caccd0',
        borderWidth: 2,
        borderRadius: 10,
    };

    public static tooltipConsolidatedData: any = {
        formatter: (params: any) => {
            const { code, subunit } = currencyUtils.getActiveCurrencyData();
            const tooltipParams: ITooltipParams[] = [];

            for (let i = 0, l = params.length; i < l; i++) {
                const { seriesName, marker, value } = params[i];

                switch (params[i].seriesName) {
                    case 'Unique players':
                    case 'New players':
                    case 'Total spins':
                    case 'Rounds':
                        tooltipParams.push({
                            seriesName,
                            marker,
                            value:
                                value !== undefined
                                    ? dataFormatterUtils.getFormattedNumber(value)
                                    : '-',
                        });
                        break;
                    case 'RTP':
                        tooltipParams.push({
                            seriesName,
                            marker,
                            value:
                                value !== undefined
                                    ? dataFormatterUtils.getFormattedNumber(value.toFixed(2)) + '%'
                                    : '-',
                        });
                        break;
                    default:
                        tooltipParams.push({
                            seriesName,
                            marker,
                            value:
                                value !== undefined
                                    ? new Intl.NumberFormat('en', {
                                          minimumFractionDigits: subunit.toString().length - 1,
                                      }).format(value) +
                                      ' ' +
                                      code
                                    : '-',
                        });
                        break;
                }
            }

            return `<div class='chart-tooltip'>
                <div class='title'>${params[0].axisValue}</div>
                ${tooltipParams
                    .map(
                        (tooltipParam: ITooltipParams) => `<div class='item'>
                        <span class='item-name'>${tooltipParam.marker} ${tooltipParam.seriesName}: </span>
                        <span class='item-value'>${tooltipParam.value}</span>
                        </div>`,
                    )
                    .join('')}
            </div>`;
        },
    };

    public static tooltipBrokenDownDataOptions(activeChart: chartKey) {
        return {
            formatter: (params: any) => {
                const { code, subunit } = currencyUtils.getActiveCurrencyData();
                const tooltipParams: ITooltipParams[] = [];
                const getTooltipParams = (seriesName: string, marker: string, value: number) => {
                    switch (activeChart) {
                        case 'spins':
                        case 'players':
                        case 'newPlayers':
                            tooltipParams.push({
                                seriesName,
                                marker,
                                value:
                                    value !== undefined
                                        ? dataFormatterUtils.getFormattedNumber(value)
                                        : '-',
                            });
                            break;
                        case 'rtp':
                            tooltipParams.push({
                                seriesName,
                                marker,
                                value:
                                    value !== undefined
                                        ? dataFormatterUtils.getFormattedNumber(+value.toFixed(2)) +
                                          '%'
                                        : '-',
                            });
                            break;
                        default:
                            tooltipParams.push({
                                seriesName,
                                marker,
                                value:
                                    value !== undefined
                                        ? new Intl.NumberFormat('en', {
                                              minimumFractionDigits: subunit.toString().length - 1,
                                          }).format(value) +
                                          ' ' +
                                          code
                                        : '-',
                            });
                            break;
                    }
                };
                let tooltipTitle;

                if (Array.isArray(params)) {
                    for (let i = 0, l = params.length; i < l; i++) {
                        const { seriesName, marker, value } = params[i];

                        getTooltipParams(seriesName, marker, value);
                        tooltipTitle = params[0].axisValue;
                    }
                } else {
                    const { seriesName, marker, value } = params;

                    getTooltipParams(seriesName, marker, value);
                    tooltipTitle = params.seriesName;
                }

                return `<div class='chart-tooltip'>
                    <div class='title'>${tooltipTitle}</div>
                    ${tooltipParams
                        .map(
                            (tooltipParam: ITooltipParams) => `<div class='item'>
                            <span class='item-name'>${tooltipParam.marker} ${tooltipParam.seriesName}: </span>
                            <span class='item-value'>${tooltipParam.value}</span>
                            </div>`,
                        )
                        .join('')}
                </div>`;
            },
        };
    }

    public static commonOptions: any = {
        grid: {
            left: '3%',
            right: '2%',
            bottom: '3%',
            top: '3%',
            containLabel: true,
        },
    };

    public static commonAxisXStyles: any = {
        type: 'category',
        axisTick: {
            alignWithLabel: true,
        },
        axisLabel: {
            margin: 10,
            fontSize: 12,
        },
        axisLine: {
            lineStyle: { color: '#caccd0' },
            show: false,
        },
    };

    public static commonAxisYStyles: any = {
        type: 'value',
        minInterval: 1,
        axisLine: {
            lineStyle: { color: '#caccd0' },
            show: false,
        },
        axisLabel: {
            formatter(value: number): string {
                return getAbbreviateNumber(value);
            },
            margin: 10,
            verticalAlign: 'bottom',
            fontSize: 12,
        },
    };
}
