import * as React from 'react';
import {useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import '../../commonCPStyles.scss';
import {Dispatch} from 'redux';
import {ContentComponent} from 'Components/ContentComponent/ContentComponent';
import {Table} from 'antd';
import {IJobScheduleData} from 'helpers/interfaces';
import {HeaderTitleComponent} from 'Components/ContentComponent/HeaderTitleComponent';
import {ColumnsType} from 'antd/es/table';
import "./UnfinishedRoundJobs.scss";


import {
    getUnfinishedRoundsJobs
} from "../../../redux/actions/configProvider/unfinished-rounds-job-actions";
import {
    getTotalNumberOfElementsData,
    getUnfinishedRoundsJobsData
} from "../../../redux/selectors/configProvider/unfinished-rounds-jobs-selector";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";

interface IProps {
    data: IJobScheduleData[];
    getUnfinishedRoundsJobs: Function;
    totalItems: number;
    loading: boolean;
}

const UnfinishedRoundJobs=  (props: IProps) => {
    const { t } = useTranslation();
    const { data, getUnfinishedRoundsJobs, loading } = props;
    const getData = useMemo(
        () =>
            data
                ? data.map((jobsData) => ({
                      ...jobsData,
                      key: jobsData.brandName,
                  }))
                : [],
        [data],
    );

    useEffect(() => {
        getUnfinishedRoundsJobs();
    }, [getUnfinishedRoundsJobs]);

    const columns: ColumnsType<IJobScheduleData> = [
        {
            title: t('job_brand_name'),
            dataIndex: 'brandName',
        },
        {
            title: t('job_operator_name'),
            dataIndex: 'operatorName',
            sorter: (a, b) => a.operatorName.localeCompare(b.operatorName),
        },
        {
            title: t('job_action_type'),
            dataIndex: 'actionType',
            sorter: (a, b) => a.actionType.localeCompare(b.actionType),
        },
        {
            title: t('job_schedule_description'),
            dataIndex: 'scheduleDescription',
        },
        {
            title: t('job_last_run'),
            dataIndex: 'lastExecutedSuccessfullyAt',
            render: (text, record) => (
                    <>
                        <span>
                            {record.lastExecutedSuccessfullyAt ? dayjs(record.lastExecutedSuccessfullyAt).format('YYYY-MM-DD HH:mm:ss') : ''}
                        </span>
                        <span className="status-message">
                            {record.message}
                        </span>
                    </>
            ),
        },
    ];

    return (
        <ContentComponent
            header={
                <div className="header-line cp-header-line">
                    <HeaderTitleComponent
                        title={t('job_schedule')}
                        customBreadcrumbs={<div>{t('all')}</div>}
                    />
                </div>
            }
            innerContent={
                <Table
                    pagination={{ defaultPageSize: 15 }}
                    dataSource={getData}
                    columns={columns}
                    loading={loading}
                    size={'middle'}
                    footer={() => <></>}
                />
            }
        />
);
};

const mapStateToProps = (state: any) => ({
    data: getUnfinishedRoundsJobsData(state),
    totalItems: getTotalNumberOfElementsData(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getUnfinishedRoundsJobs: (data: any) => dispatch(getUnfinishedRoundsJobs(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(UnfinishedRoundJobs);
