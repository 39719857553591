import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined, StopOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { MainButton } from '../../../../Components/CustomButtons/CustomButtons';
import React from 'react';
import { Dispatch } from 'redux';
import {
    terminateCampaign,
    deleteCampaign,
} from '../../../../redux/actions/backoffice/promotions-actions';
import { connect } from 'react-redux';
import { IPromotionData } from '../../../../helpers/interfaces';

const { confirm } = Modal;

interface ICampaignHeaderButtonProps {
    id: number | string | undefined;
    status: 'ONGOING' | 'UPCOMING' | 'ENDED';
    data: IPromotionData;
    deleteCampaign: Function;
    terminateCampaign: Function;
    requestCampaign: Function;
}

const CampaignHeaderButton = ({
    id,
    status,
    data,
    deleteCampaign,
    terminateCampaign,
    requestCampaign,
}: ICampaignHeaderButtonProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const componentMap = {
        ONGOING: {
            icon: <StopOutlined />,
            buttonText: t('terminate_campaign'),
            title: t('do_you_want_to_terminate_this_campaign'),
            content: t('after_terminating_the_campaign'),
            onOk: () => {
                terminateCampaign({ id, force: true });
                setTimeout(() => {
                    requestCampaign();
                }, 1000);
            },
        },
        ENDED: {
            icon: <StopOutlined />,
            buttonText: t('terminate_campaign'),
            title: t('do_you_want_to_terminate_this_campaign'),
            content: t('after_terminating_the_campaign'),
            onOk: () => {
                terminateCampaign({ id, force: true });
                setTimeout(() => {
                    requestCampaign();
                }, 1000);
            },
        },
        UPCOMING: {
            icon: <DeleteOutlined />,
            buttonText: t('remove_campaign'),
            title: t('do_you_want_to_remove_this_campaign'),
            content: null,
            onOk: () => {
                deleteCampaign(id);
                navigate('/casino/promotions/');
            },
        },
    };

    const shouldRenderForEnded = () => {
        const startDateObj = new Date(data.startDate);
        startDateObj.setDate(startDateObj.getDate() + data.expiresInDays);

        return startDateObj > new Date();
    };

    if (status === 'ENDED' && !shouldRenderForEnded()) {
        return null;
    }

    const showConfirm = () => {
        const { icon, title, content, onOk } = componentMap[status];

        confirm({
            title,
            icon,
            content,
            okText: t('yes'),
            okType: 'danger',
            onOk: onOk,
            onCancel() {},
        });
    };

    return (
        <MainButton onClick={() => showConfirm()}>
            <>
                {componentMap[status].icon} {componentMap[status].buttonText}
            </>
        </MainButton>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    deleteCampaign: (data: any) => dispatch(deleteCampaign(data)),
    terminateCampaign: (data: any) => dispatch(terminateCampaign(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignHeaderButton);
