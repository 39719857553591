import { IAction } from 'helpers/interfaces';
import { UnfinishedRoundsJobTypes } from '../../actions/configProvider/unfinished-rounds-job-actions';
import { Messages } from '../../../utils';
import i18next from 'i18next';

export interface IUnfinishedRoundsJobData {
    data?: any[] | null;
    totalNumberOfElements?: number | null;
}

export const unfinishedRoundsJobData: IUnfinishedRoundsJobData = {
    data: null,
    totalNumberOfElements: null,
};

const unfinishedRoundsJobReducer = (state = unfinishedRoundsJobData, actions: IAction) => {
    switch (actions.type) {
        case UnfinishedRoundsJobTypes.UNFINISHED_ROUNDS_JOBS_REQUEST: {
            return { ...state };
        }

        case UnfinishedRoundsJobTypes.UNFINISHED_ROUNDS_JOBS_SUCCESS: {
            return {
                ...state,
                data: actions.payload.unfinishedRoundJobs,
            };
        }

        case UnfinishedRoundsJobTypes.UNFINISHED_ROUNDS_JOBS_FAILED: {
            return { ...state };
        }

        case UnfinishedRoundsJobTypes.RUN_JOB_MANUALLY_REQUEST: {
            return { ...state };
        }

        case UnfinishedRoundsJobTypes.RUN_JOB_MANUALLY_SUCCESS: {
            setTimeout(() => {
                Messages.success(i18next.t('job_have_been_successfully_run'));
            }, 5000);

            return state;
        }

        case UnfinishedRoundsJobTypes.RUN_JOB_MANUALLY_FAILED: {
            return { ...state };
        }

        default:
            return { ...state };
    }
};

export default unfinishedRoundsJobReducer;
