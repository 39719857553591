export const UnfinishedRoundsTypes = {
    UNFINISHED_ROUNDS_REQUEST: 'UNFINISHED_ROUNDS_REQUEST',
    UNFINISHED_ROUNDS_SUCCESS: 'UNFINISHED_ROUNDS_SUCCESS',
    UNFINISHED_ROUNDS_FAILED: 'UNFINISHED_ROUNDS_FAILED',
    APPLY_ACTION_REQUEST: 'APPLY_ACTION_REQUEST',
    APPLY_ACTION_SUCCESS: 'APPLY_ACTION_SUCCESS',
    APPLY_ACTION_FAILED: 'APPLY_ACTION_FAILED',
};

export const getUnfinishedRounds = (payload?: any) => ({
    type: UnfinishedRoundsTypes.UNFINISHED_ROUNDS_REQUEST,
    payload,
});
export const getUnfinishedRoundsSuccess = (payload?: any) => ({
    type: UnfinishedRoundsTypes.UNFINISHED_ROUNDS_SUCCESS,
    payload,
});
export const applyAction = (payload?: any) => ({
    type: UnfinishedRoundsTypes.APPLY_ACTION_REQUEST,
    payload,
});
export const applyActionSuccess = (payload?: any) => ({
    type: UnfinishedRoundsTypes.APPLY_ACTION_SUCCESS,
    payload,
});
