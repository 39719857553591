import { IAction } from 'helpers/interfaces';
import { UnfinishedRoundsTypes } from '../../actions/configProvider/unfinished-rounds-actions';
import { Messages } from '../../../utils';

export interface IUnfinishedRoundsData {
    data?: any[] | null;
    totalNumberOfElements?: number | null;
}

export const unfinishedRoundsData: IUnfinishedRoundsData = {
    data: null,
    totalNumberOfElements: null,
};

const unfinishedRoundsReducer = (state = unfinishedRoundsData, actions: IAction) => {
    switch (actions.type) {
        case UnfinishedRoundsTypes.UNFINISHED_ROUNDS_REQUEST: {
            return { ...state };
        }

        case UnfinishedRoundsTypes.UNFINISHED_ROUNDS_SUCCESS: {
            return {
                ...state,
                data: actions.payload.content,
                totalNumberOfElements: actions.payload._metadata.totalNumberOfElements,
            };
        }

        case UnfinishedRoundsTypes.UNFINISHED_ROUNDS_FAILED: {
            return { ...state };
        }

        case UnfinishedRoundsTypes.APPLY_ACTION_REQUEST: {
            return { ...state };
        }

        case UnfinishedRoundsTypes.APPLY_ACTION_SUCCESS: {
            setTimeout(() => {
                Messages.success(actions.payload.message);
            }, 5000);

            return state;
        }

        case UnfinishedRoundsTypes.APPLY_ACTION_FAILED: {
            return { ...state };
        }

        default:
            return { ...state };
    }
};

export default unfinishedRoundsReducer;
