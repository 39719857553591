import { createSelector } from 'reselect';

const getUnfinishedRoundsJobs = (state: any) => state.unfinishedRoundsJobReducer.data;
const getTotalNumberOfElements = (state: any) =>
    state.unfinishedRoundsJobReducer.totalNumberOfElements;
const getLoadingStateData = (state: any) =>
    state.unfinishedRoundsJobReducer.loading;

export const getUnfinishedRoundsJobsData = createSelector(
    getUnfinishedRoundsJobs,
    (state) => state,
);
export const getTotalNumberOfElementsData = createSelector(
    getTotalNumberOfElements,
    (state) => state,
);
export const getLoadingState = createSelector(
    getLoadingStateData,
    (state) => state,
);
