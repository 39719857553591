import {
    IAction,
    IPromotionData,
    IPromotionPlayer,
    IPromotionSummary,
} from '../../../helpers/interfaces';
import { PromotionTypes } from '../../actions/backoffice/promotions-actions';
import { errorHandler } from '../../../helpers/errorHandler';
import { dataFormatterUtils, Messages } from '../../../utils';
import i18next from 'i18next';

interface IPromotionsState {
    allFreeRoundCampaigns: IPromotionData[] | null;
    allFreeRoundCampaignsTotal: number | null;
    allAwardedCampaigns: any | null;
    allAwardedCampaignsTotal: number | null;
    csv: any;
    freeRoundsCampaignData: IPromotionData | null;
    freeRoundsCampaignSummary: IPromotionSummary | null;
    awardedCampaignData: IPromotionData | null;
    awardedCampaignSummary: IPromotionSummary | null;
    isLoading: boolean;
    error: string | null;
    freeRoundsCampaignPlayers: IPromotionPlayer[] | null;
    freeRoundsCampaignPlayersTotal: number | null;
    awardedCampaignPlayers: IPromotionPlayer[] | null;
    awardedCampaignPlayersTotal: number | null;
    campaignHasBeenCreated: boolean;
    gamesByPriceAreLoading: boolean;
    gamesByPrice: any;
    clonedCampaign: any | null;
    clonedCampaignCsv: any;
    isClonedCampaignLoading: boolean;
    shouldUpdateData: boolean | null;
    campaignCreationLoading: boolean;
}

const promotionsInitialState: IPromotionsState = {
    allFreeRoundCampaigns: null,
    allFreeRoundCampaignsTotal: null,
    allAwardedCampaigns: null,
    allAwardedCampaignsTotal: null,
    isLoading: false,
    error: null,
    csv: null,
    freeRoundsCampaignData: null,
    freeRoundsCampaignSummary: null,
    awardedCampaignSummary: null,
    awardedCampaignData: null,
    freeRoundsCampaignPlayers: null,
    freeRoundsCampaignPlayersTotal: null,
    awardedCampaignPlayers: null,
    awardedCampaignPlayersTotal: null,
    campaignHasBeenCreated: false,
    gamesByPriceAreLoading: false,
    gamesByPrice: null,
    clonedCampaign: null,
    clonedCampaignCsv: null,
    isClonedCampaignLoading: false,
    shouldUpdateData: false,
    campaignCreationLoading: false,
};

const promotionsReducer = (state = promotionsInitialState, actions: IAction) => {
    switch (actions.type) {
        case PromotionTypes.GET_FREE_ROUNDS_PROMOTIONS_REQUEST: {
            return { ...state, isLoading: true, shouldUpdateData: false, error: null };
        }

        case PromotionTypes.GET_FREE_ROUNDS_PROMOTIONS_SUCCESS: {
            const { content, total } = actions.payload;

            return {
                ...state,
                allFreeRoundCampaigns: content,
                allFreeRoundCampaignsTotal: total,
                isLoading: false,
            };
        }

        case PromotionTypes.GET_AWARDED_PROMOTIONS_REQUEST: {
            return { ...state, isLoading: true, shouldUpdateData: false, error: null };
        }

        case PromotionTypes.GET_AWARDED_PROMOTIONS_SUCCESS: {
            const { content, total } = actions.payload;

            return {
                ...state,
                allAwardedCampaigns: content,
                allAwardedCampaignsTotal: total,
                isLoading: false,
            };
        }

        case PromotionTypes.GET_FREE_ROUNDS_PROMOTIONS_FAILED:
        case PromotionTypes.GET_AWARDED_PROMOTIONS_FAILED: {
            const error = errorHandler.getErrorText(actions.payload);

            return { ...state, error, isLoading: false };
        }

        case PromotionTypes.CREATE_PROMOTION_WITH_CSV_REQUEST:
        case PromotionTypes.CREATE_FREE_ROUNDS_PROMOTION_REQUEST: {
            return { ...state, shouldUpdateData: false };
        }

        case PromotionTypes.CREATE_AWARDED_PROMOTION_REQUEST: {
            return { ...state, campaignCreationLoading: true };
        }

        case PromotionTypes.UPLOAD_CSV_SUCCESS:
        case PromotionTypes.CREATE_FREE_ROUNDS_PROMOTION_SUCCESS: {
            Messages.success(i18next.t('new_campaign_has_been_created'));

            return { ...state, shouldUpdateData: true };
        }

        case PromotionTypes.CREATE_AWARDED_PROMOTION_SUCCESS: {
            Messages.success(i18next.t('new_campaign_has_been_created'));

            return { ...state, campaignHasBeenCreated: true, campaignCreationLoading: false };
        }

        case PromotionTypes.CREATE_FREE_ROUNDS_PROMOTION_FAILED:
        case PromotionTypes.CREATE_AWARDED_PROMOTION_FAILED: {
            const error = errorHandler.getErrorText(actions.payload);

            return { ...state, error };
        }

        case PromotionTypes.GET_FREE_ROUNDS_CAMPAIGN_REQUEST: {
            return { ...state, shouldUpdateData: false };
        }

        case PromotionTypes.GET_FREE_ROUNDS_CAMPAIGN_SUCCESS: {
            const { campaignData, summary, players, csv } = actions.payload;

            return {
                ...state,
                csv,
                freeRoundsCampaignData: campaignData,
                freeRoundsCampaignSummary: summary,
                freeRoundsCampaignPlayers: dataFormatterUtils.getFormattedData(
                    players.content,
                    false,
                    campaignData.currencyCode,
                ),
                freeRoundsCampaignPlayersTotal: players.total,
            };
        }

        case PromotionTypes.GET_FREE_ROUNDS_CAMPAIGN_FAILED: {
            const error = errorHandler.getErrorText(actions.payload);

            return { ...state, error };
        }

        case PromotionTypes.CLEAR_CAMPAIGN_DATA_REQUEST: {
            return {
                ...state,
                csv: null,
                freeRoundsCampaignData: null,
                awardedCampaignData: null,
                freeRoundsCampaignSummary: null,
                awardedCampaignSummary: null,
                freeRoundsCampaignPlayers: null,
                awardedCampaignPlayers: null,
                freeRoundsCampaignPlayersTotal: null,
                awardedCampaignPlayersTotal: null,
            };
        }

        case PromotionTypes.UPLOAD_CSV_FAILED: {
            return { ...state };
        }

        case PromotionTypes.DELETE_CAMPAIGN_REQUEST: {
            return { ...state };
        }

        case PromotionTypes.DELETE_CAMPAIGN_SUCCESS: {
            Messages.success(i18next.t('campaign_has_been_deleted'));

            return { ...state };
        }

        case PromotionTypes.DELETE_CAMPAIGN_FAILED: {
            return { ...state };
        }

        case PromotionTypes.TERMINATE_CAMPAIGN_REQUEST: {
            return { ...state };
        }

        case PromotionTypes.TERMINATE_CAMPAIGN_SUCCESS: {
            Messages.success(i18next.t('campaign_status_has_been_changed_successfully'));

            return { ...state };
        }
        case PromotionTypes.TERMINATE_CAMPAIGN_FAILED: {
            return { ...state };
        }

        case PromotionTypes.EDIT_PROMOTION_SUCCESS: {
            return { ...state, campaignHasBeenCreated: true, shouldUpdateData: true };
        }

        case PromotionTypes.GET_GAMES_BY_PROMOTION_PRICE_REQUEST: {
            return { ...state, gamesByPriceAreLoading: true };
        }

        case PromotionTypes.GET_GAMES_BY_PROMOTION_PRICE_SUCCESS: {
            return { ...state, gamesByPriceAreLoading: false, gamesByPrice: actions.payload };
        }

        case PromotionTypes.CLONE_CAMPAIGN_REQUEST: {
            return { ...state, isCloneCampaignLoading: true };
        }

        case PromotionTypes.CLONE_CAMPAIGN_SUCCESS: {
            const { campaignData, csv } = actions.payload;

            return {
                ...state,
                clonedCampaign: campaignData,
                isCloneCampaignLoading: false,
                clonedCampaignCsv: csv,
            };
        }

        case PromotionTypes.GET_AWARDED_CAMPAIGN_REQUEST: {
            return { ...state, isLoading: true };
        }

        case PromotionTypes.GET_AWARDED_CAMPAIGN_SUCCESS: {
            const { campaignData, summary } = actions.payload;

            return {
                ...state,
                awardedCampaignData: campaignData,
                awardedCampaignSummary: summary,
                isLoading: false,
            };
        }

        case PromotionTypes.GET_AWARDED_CAMPAIGN_FAILED: {
            const error = errorHandler.getErrorText(actions.payload);

            return { ...state, error, isLoading: false };
        }

        case PromotionTypes.GET_AWARDED_CAMPAIGN_PLAYERS_REQUEST: {
            return { ...state, isLoading: true, shouldUpdateData: false };
        }

        case PromotionTypes.GET_AWARDED_CAMPAIGN_PLAYERS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                awardedCampaignPlayers: actions.payload.content,
                awardedCampaignPlayersTotal: actions.payload.total,
            };
        }

        case PromotionTypes.EDIT_AWARDED_CAMPAIGN_REQUEST: {
            return { ...state, isLoading: true };
        }

        case PromotionTypes.EDIT_AWARDED_CAMPAIGN_SUCCESS: {
            Messages.success('Campaign has been changed successfully');

            return { ...state, isLoading: false };
        }

        case PromotionTypes.IMPORT_PLAYERS_CSV_REQUEST: {
            return { ...state, shouldUpdateData: false };
        }

        case PromotionTypes.IMPORT_PLAYERS_CSV_SUCCESS: {
            Messages.success('CSV was successfully imported successfully');

            return { ...state, shouldUpdateData: true };
        }

        case PromotionTypes.REMOVE_PLAYERS_FROM_CAMPAIGN_REQUEST: {
            return { ...state, shouldUpdateData: false };
        }

        case PromotionTypes.REMOVE_PLAYERS_FROM_CAMPAIGN_SUCCESS: {
            Messages.success('Players has been successfully removed');

            return { ...state, shouldUpdateData: true };
        }

        case PromotionTypes.ADD_PLAYER_TO_CAMPAIGN_REQUEST: {
            return { ...state, shouldUpdateData: false };
        }

        case PromotionTypes.ADD_PLAYER_TO_CAMPAIGN_SUCCESS: {
            Messages.success('Player has been successfully added');

            return { ...state, shouldUpdateData: true };
        }

        case PromotionTypes.RESET_NEW_PROMOTION: {
            return {
                ...state,
                clonedCampaign: null,
                clonedCampaignCsv: null,
                campaignHasBeenCreated: false,
                campaignCreationLoading: false,
                gamesByPriceAreLoading: false,
                gamesByPrice: null,
            };
        }

        default:
            return state;
    }
};

export default promotionsReducer;
