import * as React from 'react';
import { ConfigProvider, Layout, Modal, Space } from 'antd';
import { connect } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import LoginComponent from './Containers/LogIn/LoginComponent';
import { browserDetection, localStorageHelper, projectDetection } from 'utils';
import './App.scss';
import MainMenu from './Containers/MainMenu/MainMenu';
import { routes } from 'utils/routes';
import ReactGA from 'react-ga';
import { WithTranslation, withTranslation } from 'react-i18next';
import { locales } from 'utils/locales';
import { Overlay } from 'Components/Overlay/Overlay';
import { mediaQueryHooksHOC } from 'utils';
import { isAuthorised, isLoggingOut, userState } from 'redux/selectors/user-selectors';
import { IMediaQuery, ITimeZoneListItem } from 'helpers/interfaces';
import { Dispatch } from 'redux';
import { logout } from 'redux/actions/user-actions';
import { animateScroll as scroll } from 'react-scroll';
import { UpOutlined } from '@ant-design/icons/lib';
import { withRouter } from './helpers/HOCs/withRouter';
import { Locale } from 'antd/lib/locale';
import DashboardMobileComponent from './mobile/DashboardMobileComponent';
import ReportsComponent from './Containers/Reports/ReportsComponent';
import MainHeader from './Containers/MainHeader/MainHeader';
import dayjs from 'dayjs';
import { IS_KOALA } from './utils/config';

type History = {
    push: (to: string | number, state?: any) => void;
    listen: (callback: (location: Location) => void) => () => void;
};

interface IProps {
    mediaQuery: IMediaQuery;
    userState: any;
    logout: Function;
    location: Location;
    isAuthorised: boolean;
    isLoggingOut: boolean;
    history: History;
    navigate: ReturnType<typeof useNavigate>;
}

interface IState {
    antLocale: Locale;
    isOverlayVisible: boolean;
    isToTopButtonVisible: boolean;
}

let timerId: any;

ReactGA.initialize('UA-133766066-3');

export const Context = React.createContext({});

const currentLocale = (locales as any)[localStorageHelper.getChainedValue('locale')] || locales.en;

const antdThemeConfig = {
    token: { colorPrimary: IS_KOALA ? '#ffb211' : '#CF0920' },
    components: {
        Table: {
            headerBg: '#ffffff',
            footerBg: '#ffffff',
            rowHoverBg: '#f0f3f8',
            headerSortActiveBg: '#feeeee',
            cellPaddingInlineMD: 16,
        },
    },
};

dayjs.locale(currentLocale.moment);

class App extends React.Component<IProps & WithTranslation, IState> {
    constructor(props: IProps & WithTranslation) {
        super(props);

        this.state = {
            antLocale: currentLocale.ant,
            isOverlayVisible: false,
            isToTopButtonVisible: false,
        };

        props.history.listen((location) => {
            this.addPageTrackingForGA(location);

            if (this.props.isAuthorised && location.pathname === '/') {
                this.props.logout();
            }
        });
    }

    addPageTrackingForGA = ({ pathname }: any) => {
        ReactGA.set({ page: pathname });
        ReactGA.pageview(pathname);
    };

    componentDidMount = () => {
        const root = document.documentElement;
        const variables: any = {
            '--accent-color': IS_KOALA ? 'var(--koala-accent)' : 'var(--default-accent)',
            '--background-image': IS_KOALA
                ? 'var(--koala-background)'
                : 'var(--default-background)',
            '--login-logo': IS_KOALA ? 'var(--koala-login-logo)' : 'var(--default-login-logo)',
            '--logo': IS_KOALA ? 'var(--koala-logo)' : 'var(--default-logo)',
        };

        Object.keys(variables).forEach((key) => {
            root.style.setProperty(key, variables[key]);
        });

        this.addDeviceRelatedClasses();

        document.addEventListener('scroll', () => {
            const toTopButton = document.querySelector('.toTop');
            const offset = window.pageYOffset;

            if (timerId) {
                return;
            }

            timerId = setTimeout(function () {
                if (offset > 200) {
                    toTopButton && toTopButton.classList.add('visible');
                } else {
                    toTopButton && toTopButton.classList.remove('visible');
                }
                timerId = undefined;
            }, 30);
        });
    };

    addDeviceRelatedClasses = () =>
        this.props.mediaQuery.isDesktop && document.body.classList.add('desktop');

    changeAntLocale = (lang: 'en' | 'zh') => {
        localStorageHelper.set('locale', lang);
        this.setState({ antLocale: locales[lang].ant });
    };

    changeOverlaysState = () =>
        this.setState((state) => ({
            isOverlayVisible: !state.isOverlayVisible,
        }));

    insertNotSupportedModal = () => (
        <Modal className="notSupported" title="Error" open centered>
            <p>{this.props.t('not_supported')}</p>
        </Modal>
    );

    getRoutes = () => {
        const projectName = projectDetection.getProjectName();
        const projectRoutes = routes.filter((route: any) => route.belonging.includes(projectName));

        return projectRoutes.map((route: any) => (
            <Route key={route.path} path={route.path} element={route.innerComponent} />
        ));
    };

    getTimezoneData = () => {
        const list = localStorageHelper.getChainedValue('user.timezoneList');
        const currentTimeZone = localStorageHelper.getChainedValue('user.preferences.timeZone');

        if (list && currentTimeZone) {
            return list.find((elem: ITimeZoneListItem) => currentTimeZone === elem.id);
        } else {
            return null;
        }
    };

    navigateToLoginPage = () => {
        const { isAuthorised, isLoggingOut } = this.props;

        if (
            !isAuthorised &&
            !isLoggingOut &&
            window.location.pathname !== '/login' &&
            window.location.pathname !== '/'
        ) {
            localStorageHelper.set('requestedPage', window.location.pathname);
        }

        return <LoginComponent />;
    };

    componentDidUpdate(prevProps: IProps) {
        const { isMobile, isLandscapedMobile } = this.props.mediaQuery;
        const { isAuthorised, navigate } = this.props;
        const { isBO } = projectDetection;
        const shouldNavigateToDashboard =
            isAuthorised && isBO() && (isMobile || isLandscapedMobile);

        if (!prevProps.isAuthorised && isAuthorised) {
            const requestedPage = localStorageHelper.getChainedValue('requestedPage');

            if (isBO()) {
                if (requestedPage) {
                    navigate(requestedPage);
                    localStorageHelper.remove('requestedPage');
                } else if (
                    !this.props.location.pathname ||
                    this.props.location.pathname === '/login'
                ) {
                    if (shouldNavigateToDashboard) {
                        navigate('/casino/dashboard');
                    } else {
                        navigate('/casino/reports');
                    }
                }
            } else {
                navigate('/config-provider/tools');
            }
        }
    }

    render() {
        const { antLocale, isOverlayVisible } = this.state;
        const isNotIFrameWindow = window.location.pathname.indexOf('index.html') === -1;
        const { isNotMobile, isLandscapedMobile } = this.props.mediaQuery;
        const { isAuthorised } = this.props;

        return (
            <Context.Provider
                value={{
                    t: this.props.t,
                    changeAntLocale: this.changeAntLocale,
                    changeOverlaysState: this.changeOverlaysState,
                    timezone: this.getTimezoneData(),
                }}
            >
                <ConfigProvider locale={antLocale} theme={antdThemeConfig}>
                    <Space>
                        <Layout style={{ minHeight: '100vh', minWidth: '100vw' }} hasSider>
                            {browserDetection.isIE() ? (
                                this.insertNotSupportedModal()
                            ) : (
                                <>
                                    {isAuthorised &&
                                        isNotIFrameWindow &&
                                        isNotMobile &&
                                        !isLandscapedMobile && <MainMenu />}
                                    <Layout
                                        style={{
                                            backgroundColor: '#f0f3f8',
                                            height: '100vh',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <div style={{ overflow: 'auto' }}>
                                            {isAuthorised && isNotIFrameWindow && <MainHeader />}
                                            <Routes>
                                                {!isAuthorised && (
                                                    <>
                                                        <Route
                                                            path="/*"
                                                            element={
                                                                <Navigate to={'login'} replace />
                                                            }
                                                        />
                                                        <Route
                                                            path="/login"
                                                            element={this.navigateToLoginPage()}
                                                        />
                                                    </>
                                                )}
                                                {isAuthorised && (
                                                    <>
                                                        <Route
                                                            path="/dashboard"
                                                            element={<DashboardMobileComponent />}
                                                        />
                                                        <Route
                                                            path="/reports"
                                                            element={<ReportsComponent />}
                                                        />
                                                        {this.getRoutes()}
                                                    </>
                                                )}
                                            </Routes>
                                        </div>
                                    </Layout>
                                </>
                            )}
                        </Layout>
                    </Space>
                    <button className="toTop" onClick={() => scroll.scrollToTop()}>
                        <UpOutlined />
                    </button>
                    <Overlay isVisible={isOverlayVisible} switchState={this.changeOverlaysState} />
                </ConfigProvider>
            </Context.Provider>
        );
    }
}

const mapStateToProps = (state: any) => ({
    userState: userState(state),
    isAuthorised: isAuthorised(state),
    isLoggingOut: isLoggingOut(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: () => dispatch(logout()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(mediaQueryHooksHOC(withRouter(App))));
