import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { IAction } from '../../../helpers/interfaces';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getLoginRequestHeader } from '../../../utils';
import { of } from 'rxjs';
import {
    getUnfinishedRoundsJobsSuccess,
    UnfinishedRoundsJobTypes
} from '../../actions/configProvider/unfinished-rounds-job-actions';
import { apiError } from '../../actions/user-actions';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/config';

export const unfinishedRoundsJobsEpics = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(UnfinishedRoundsJobTypes.UNFINISHED_ROUNDS_JOBS_REQUEST),
        mergeMap(() => {
            const headers = getLoginRequestHeader('application/json');

            return from(
                axios.get(`${SERVER_URL}/rounds/unfinished-rounds/jobs-schedule`, {
                    headers,
                }),
            ).pipe(
                map(({ data }) => getUnfinishedRoundsJobsSuccess(data)),
                catchError((error) =>
                    of(apiError(error, UnfinishedRoundsJobTypes.UNFINISHED_ROUNDS_JOBS_REQUEST)),
                ),
            );
        }),
    );

