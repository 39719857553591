import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    getCampaignData,
    getCampaignPlayersData,
    getCampaignPlayersDataCSV,
    getCampaignPlayersTotalData,
    getCampaignSummaryData,
    getPromotionsErrorData,
    shouldUpdateData,
} from '../../../../redux/selectors/backoffice/propmotions-selectors';
import { Dispatch } from 'redux';
import {
    clearCampaignData,
    deleteCampaign,
    getCampaign,
} from '../../../../redux/actions/backoffice/promotions-actions';
import { connect, useDispatch } from 'react-redux';
import { ContentComponent } from '../../../../Components/ContentComponent/ContentComponent';
import { HeaderTitleComponent } from '../../../../Components/ContentComponent/HeaderTitleComponent';
import { ErrorComponent } from '../../../Errors/ErrorComponent';
import { Loader } from '../../../../Components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import {
    IPaginationData,
    IPromotionData,
    IPromotionPlayer,
    IPromotionSummary,
} from '../../../../helpers/interfaces';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Select, Space, Table } from 'antd';
import Search from 'antd/es/input/Search';
import CampaignHeaderButton from './CampaignHeaderButton';
import PromotionInfoTable from './PromotionInfoTable';
import { localStorageHelper } from '../../../../utils';
import CampaignColumns from './CampaignColumns';
import { currencyUtils } from 'utils';
import PromotionalTotalComponent from './PromotionalTotalComponent';
import { getAllGameConfigurationsByUser } from '../../../../redux/actions/user-actions';

const PAGE_SIZE_ARRAY = ['5', '10', '20'];
const DEFAULT_AMOUNT_OF_ITEMS_PER_PAGE = 10;
const PLAYER_STATUSES = ['all_statuses', 'QUEUED', 'IN_USE', 'COMPLETED', 'EXPIRED'];

interface IProps {
    getCampaign: Function;
    deleteCampaign: Function;
    data: IPromotionData;
    summary: IPromotionSummary;
    players: IPromotionPlayer[];
    total: number;
    error: string;
    csv: any;
    isUpdateData: boolean;
    clearCampaignData: Function;
}

const CampaignControl = ({
    getCampaign,
    data,
    summary,
    csv,
    players,
    total,
    error,
    isUpdateData,
    clearCampaignData,
}: IProps) => {
    const params = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [playerIdSearchValue, setPlayerIdSearchValue] = useState('');
    const sortKey = 'id';
    const sortOrder = 'DESCENDING';
    const userActiveCurrency = currencyUtils.getActiveCurrencyData();
    const [currency, setCurrency] = useState<'user' | 'campaign'>(
        userActiveCurrency.billable ? 'user' : 'campaign',
    );
    const [currencyCode, setCurrencyCode] = useState(
        currency === 'user' ? userActiveCurrency.code : '',
    );
    const [playersStatus, setPlayersStatus] = useState('all_statuses');
    const [paginationData, setPaginationData] = useState<IPaginationData>({
        pageNumber: 1,
        pageSize: DEFAULT_AMOUNT_OF_ITEMS_PER_PAGE,
    });
    const columns = CampaignColumns(currencyCode);

    const isUserCanManageCampaigns = localStorageHelper
        .getChainedValue('user.authorities')
        .includes('MANAGE_CAMPAIGNS');

    const handlePagination = (pageNumber: number, pageSize?: number) => {
        setPaginationData((prevState: IPaginationData) => ({
            pageNumber,
            pageSize: pageSize ? pageSize : prevState.pageSize,
        }));
    };

    const requestCampaign = useCallback(() => {
        getCampaign({
            campaignId: params.campaignId,
            playerIdSearchValue,
            playersStatus,
            paginationData,
            sortKey,
            sortOrder,
            currency: currency,
        });
    }, [
        getCampaign,
        params.campaignId,
        playerIdSearchValue,
        playersStatus,
        paginationData,
        sortKey,
        sortOrder,
        currency,
    ]);

    const handleUpdateCurrency = (value: 'user' | 'campaign') => {
        setCurrency(value);
        setCurrencyCode(value === 'user' ? userActiveCurrency.code : data.currencyCode);
    };

    useEffect(() => {
        dispatch(getAllGameConfigurationsByUser());
    }, [dispatch]);

    useEffect(() => {
        if (isUpdateData) {
            requestCampaign();
        }
    }, [isUpdateData, requestCampaign]);

    useEffect(() => {
        if (data && data.currencyCode) {
            const activeCurrency = currencyUtils.getActiveCurrencyData();

            if (!activeCurrency.billable) {
                if (activeCurrency.code !== data.currencyCode) {
                    setCurrencyCode(data.currencyCode);
                }
            }
        }
    }, [data]);

    useEffect(() => {
        return () => {
            clearCampaignData();
        };
    }, [clearCampaignData]);

    useEffect(() => {
        requestCampaign();
    }, [paginationData, playersStatus, requestCampaign]);

    return (
        <ContentComponent
            header={
                <HeaderTitleComponent
                    title={data ? data.name : ''}
                    content={
                        isUserCanManageCampaigns &&
                        data &&
                        (data.status === 'ONGOING' ||
                            data.status === 'UPCOMING' ||
                            data.status === 'ENDED') ? (
                            <div className="promotion-header__buttons">
                                <CampaignHeaderButton
                                    id={params.campaignId}
                                    status={data.status}
                                    data={data}
                                    requestCampaign={requestCampaign}
                                />
                            </div>
                        ) : undefined
                    }
                    customBreadcrumbs={<Link to={`/casino/promotions`}>{t('promotions')}</Link>}
                />
            }
            preHeader={
                summary && !error ? (
                    <PromotionalTotalComponent
                        currentCurrencyCode={currencyCode}
                        onCurrencyChange={handleUpdateCurrency}
                        data={{ ...summary, ...data }}
                    />
                ) : undefined
            }
            innerContent={
                error ? (
                    <ErrorComponent error={error} />
                ) : data ? (
                    <>
                        <PromotionInfoTable data={{ ...data, csv }} />
                        <Space.Compact
                            className="broken-rounds-search__wrapper"
                            style={{ marginBottom: '8px' }}
                        >
                            <Select
                                className="campaign-player-status-selector"
                                value={playersStatus}
                                onChange={(value: string) => setPlayersStatus(value)}
                            >
                                {PLAYER_STATUSES.map((key: string) => (
                                    <Select.Option key={key} value={key}>
                                        {t(key)}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Search
                                placeholder={t('enter_player_id')}
                                value={playerIdSearchValue}
                                onChange={(e) => setPlayerIdSearchValue(e.target.value)}
                                onSearch={requestCampaign}
                                enterButton
                                suffix={
                                    <CloseCircleOutlined
                                        className={`broken-rounds-search__clear${
                                            playerIdSearchValue.length > 0 ? '__visible' : ''
                                        }`}
                                        onClick={() => setPlayerIdSearchValue('')}
                                    />
                                }
                            />
                        </Space.Compact>
                        <Table
                            rowKey={'id'}
                            dataSource={players}
                            columns={columns}
                            pagination={{
                                current: paginationData.pageNumber,
                                pageSize: paginationData.pageSize,
                                total: total,
                                onChange: handlePagination,
                                onShowSizeChange: handlePagination,
                                showSizeChanger: true,
                                pageSizeOptions: PAGE_SIZE_ARRAY,
                            }}
                            size={'small'}
                            footer={() => <></>}
                        />
                    </>
                ) : (
                    <Loader style={{ height: '90vh' }} />
                )
            }
        />
    );
};

const mapStateToProps = (state: any) => ({
    data: getCampaignData(state),
    summary: getCampaignSummaryData(state),
    players: getCampaignPlayersData(state),
    csv: getCampaignPlayersDataCSV(state),
    total: getCampaignPlayersTotalData(state),
    error: getPromotionsErrorData(state),
    isUpdateData: shouldUpdateData(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getCampaign: (data: any) => dispatch(getCampaign(data)),
    deleteCampaign: (data: any) => dispatch(deleteCampaign(data)),
    clearCampaignData: (data: any) => dispatch(clearCampaignData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignControl);
