import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal } from 'antd';
import React, { FC } from 'react';
import { Dispatch } from 'redux';
import {
    terminateCampaign,
    deleteCampaign,
} from '../../../../redux/actions/backoffice/promotions-actions';
import { connect } from 'react-redux';
import { IPromotionData } from '../../../../helpers/interfaces';
import { CopyOutlined, EllipsisOutlined } from '@ant-design/icons/lib';

const { confirm } = Modal;

interface ICampaignHeaderButtonProps {
    id: number | string | undefined;
    status: 'ONGOING' | 'UPCOMING' | 'ENDED';
    data: IPromotionData;
    deleteCampaign: Function;
    terminateCampaign: Function;
    requestCampaign: Function;
}

const CampaignHeaderButton: FC<ICampaignHeaderButtonProps> = ({
    id,
    status,
    data,
    deleteCampaign,
    terminateCampaign,
    requestCampaign,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const componentMap: Record<
        string,
        {
            icon: JSX.Element;
            buttonText: string;
            title: string;
            content: string | null;
            onOk: () => void;
        }
    > = {
        ONGOING: {
            icon: <StopOutlined />,
            buttonText: t('terminate_campaign'),
            title: t('do_you_want_to_terminate_this_campaign'),
            content: t('after_terminating_the_campaign'),
            onOk: () => {
                terminateCampaign({ id, force: true });
                setTimeout(() => {
                    requestCampaign();
                }, 1000);
            },
        },
        ENDED: {
            icon: <StopOutlined />,
            buttonText: t('terminate_campaign'),
            title: t('do_you_want_to_terminate_this_campaign'),
            content: t('after_terminating_the_campaign'),
            onOk: () => {
                terminateCampaign({ id, force: true });
                setTimeout(() => {
                    requestCampaign();
                }, 1000);
            },
        },
        UPCOMING: {
            icon: <DeleteOutlined />,
            buttonText: t('remove_campaign'),
            title: t('do_you_want_to_remove_this_campaign'),
            content: null,
            onOk: () => {
                deleteCampaign(id);
                navigate('/casino/awarded-promotions/');
            },
        },
    };

    const shouldRenderForEnded = (): boolean => {
        const startDateObj = new Date(data.startDate);
        startDateObj.setDate(startDateObj.getDate() + data.expiresInDays);
        return startDateObj > new Date();
    };

    if (status === 'ENDED' && !shouldRenderForEnded()) {
        return null;
    }

    const showConfirm = (action: 'ONGOING' | 'ENDED' | 'UPCOMING'): void => {
        const { icon, title, content, onOk } = componentMap[action];

        confirm({
            title,
            icon,
            content,
            okText: t('yes'),
            okType: 'danger',
            onOk: onOk,
            onCancel() {},
        });
    };

    const menu = (
        <Menu>
            <Menu.Item icon={componentMap[status].icon} onClick={() => showConfirm(status)}>
                {componentMap[status].buttonText}
            </Menu.Item>
            <Menu.Item
                icon={<CopyOutlined />}
                onClick={() => navigate(`/casino/awarded-promotion/clone/${id}`)}
            >
                Clone campaign
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} placement="bottomRight">
            <Button type="text" icon={<EllipsisOutlined className="rotate-90" />} />
        </Dropdown>
    );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: Dispatch) => ({
    deleteCampaign: (data: number | string | undefined) => dispatch(deleteCampaign(data)),
    terminateCampaign: (data: { id: number | string | undefined; force: boolean }) =>
        dispatch(terminateCampaign(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignHeaderButton);
