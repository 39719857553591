import * as React from 'react';
import '../ChartStyles.scss';
import { ChartConstants, chartKey } from '../ChartConstants';
import { WithTranslation, withTranslation } from 'react-i18next';
import ReactEcharts from 'echarts-for-react';
import { currencyUtils, dateFormatter, localStorageHelper } from 'utils';
import { Checkbox, Select } from 'antd';
import { ICasinoPerformanceData } from 'redux/reducers/backoffice/casino-performance-report-reducer';
import { IEChartSeries } from 'helpers/interfaces';
import ItemComponentWrapper from 'Components/ContentComponent/ItemComponentWrapper';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import dayjs from 'dayjs';

const { Option } = Select;

interface IProps {
    data: any[];
}

interface IDataGroupedByCurrency {
    currencyId: number;
    data: any[];
}

interface IState {
    activeChart: chartKey;
    sortedData: IDataGroupedByCurrency[];
    allCurrencies: string[];
    activeCurrencies: string[];
}

const defaultActiveChart: chartKey = 'bet';
const maxCurrenciesAmount = 7;
const charts: chartKey[] = ['bet', 'win', 'net', 'spins', 'rtp', 'newPlayers', 'players'];
const financialCharts: chartKey[] = ['bet', 'win', 'net'];
const chartColors = ['#00d9a0', '#3579ff', '#ffb600', '#cf0920', '#1c366a', '#ff837d', '#ff7700'];

class CasinoBrokenDownDataChart extends React.Component<IProps & WithTranslation, IState> {
    state: IState = {
        activeChart: defaultActiveChart,
        ...CasinoBrokenDownDataChart.getDataGroupedByCurrency(this.props.data),
    };

    static getDataGroupedByCurrency = (data: any) => {
        const currencyGroups: IDataGroupedByCurrency[] = [];

        data.forEach((item: any) => {
            if (!item) return;

            const group = currencyGroups.find(
                (group: IDataGroupedByCurrency) => group.currencyId === item.currencyId,
            );

            if (group) {
                group.data.push(item);
            } else {
                currencyGroups.push({
                    currencyId: item.currencyId,
                    data: [item],
                });
            }
        });

        const getSum = (data: ICasinoPerformanceData[]) =>
            data.reduce((sum: number, current: any) => sum + current[defaultActiveChart], 0);

        const sortedCurrencyGroups = currencyGroups.sort(
            (a: IDataGroupedByCurrency, b: IDataGroupedByCurrency) =>
                getSum(b.data) - getSum(a.data),
        );

        if (sortedCurrencyGroups.length > maxCurrenciesAmount) {
            sortedCurrencyGroups.length = maxCurrenciesAmount;
        }

        const sortedCurrencies: string[] = [];

        sortedCurrencyGroups.forEach(({ currencyId }) => {
            sortedCurrencies.push(currencyUtils.getCurrencyDataById(currencyId).code);
        });

        sortedCurrencyGroups.forEach((group: IDataGroupedByCurrency) => {
            group.data = group.data
                .filter(Boolean)
                .sort((a: any, b: any) => new Date(a.from).getTime() - new Date(b.from).getTime());
        });

        return {
            sortedData: sortedCurrencyGroups,
            allCurrencies: sortedCurrencies,
            activeCurrencies: sortedCurrencies,
        };
    };

    getOptions = () => {
        const dates: string[] = this.getDates();

        return (
            this.props.data && {
                activeChart: this.state.activeChart,
                color: this.getChartColors(),
                xAxis: this.getXAxis(dates),
                yAxis: this.getYAxis(),
                series: this.getSeries(dates),
                tooltip: this.getTooltips(),
                ...ChartConstants.commonOptions,
            }
        );
    };

    getChartColors = () => {
        const { activeCurrencies, allCurrencies } = this.state;
        const activeChartColors: string[] = [];

        allCurrencies.forEach((currency: string, index: number) => {
            activeCurrencies.includes(currency) && activeChartColors.push(chartColors[index]);
        });

        return activeChartColors;
    };

    getDates = () => {
        const { data } = this.props;
        const fromDates: string[] = [];
        const dates: string[] = [];
        const isAggregationPeriodDay =
            localStorageHelper.getChainedValue('user.reports.aggregationPeriod') === 'day';

        data.forEach(({ from }) => {
            if (!fromDates.includes(from)) {
                let indexToInsertDate = -1;

                fromDates.forEach((date: string, index: number) => {
                    if (dayjs(from).isBefore(date) && indexToInsertDate === -1) {
                        indexToInsertDate = index;
                    }
                });

                if (indexToInsertDate !== -1) {
                    fromDates.splice(indexToInsertDate, 0, from);
                } else {
                    fromDates.push(from);
                }
            }
        });

        fromDates.forEach((date: string) => {
            let isDateNotAdded = true;

            data.forEach(({ from, to }) => {
                if (date === from && isDateNotAdded) {
                    const fromDate = dateFormatter.getFormattedForReportDate(from);
                    const toDate = dateFormatter.getFormattedForReportDate(to);
                    const date = isAggregationPeriodDay ? fromDate : `${fromDate} - ${toDate}`;

                    dates.push(date);
                    isDateNotAdded = false;
                }
            });
        });

        return dates;
    };

    getXAxis = (data: string[]) => [{ ...ChartConstants.commonAxisXStyles, data }];

    getYAxis = () => {
        const key = this.state.activeChart || defaultActiveChart;
        const name = financialCharts.includes(key)
            ? currencyUtils.getActiveCurrencyCode()
            : key === 'rtp'
              ? '%'
              : '';

        return [{ name, ...ChartConstants.commonAxisYStyles }];
    };

    getSeries = (dates: string[]) => {
        const { sortedData, activeCurrencies } = this.state;
        const series: IEChartSeries[] = [];
        const dataByChosenCurrencies: IDataGroupedByCurrency[] = [];

        sortedData.forEach((dataGroup: IDataGroupedByCurrency) => {
            if (
                activeCurrencies.includes(
                    currencyUtils.getCurrencyDataById(dataGroup.currencyId).code,
                )
            ) {
                dataByChosenCurrencies.push(dataGroup);
            }
        });

        dataByChosenCurrencies.forEach((dataGroup: IDataGroupedByCurrency) => {
            const { data } = dataGroup;

            dates.forEach((date: string, index: number) => {
                if (data[index]) {
                    if (
                        date.indexOf(dateFormatter.getFormattedForReportDate(data[index].from)) !==
                        0
                    ) {
                        data.splice(index, 0, null);
                    }
                } else {
                    data.push(null);
                }
            });
        });

        dataByChosenCurrencies.forEach((dataGroup: IDataGroupedByCurrency) => {
            const { currencyId, data } = dataGroup;

            series.push({
                name: currencyUtils.getCurrencyDataById(currencyId).code,
                type: 'line',
                smooth: true,
                yAxisIndex: 0,
                data: this.getChartDataForSeries(data),
            });
        });

        return series;
    };

    getTooltips = () => ({
        ...ChartConstants.tooltipCommonOptions,
        ...ChartConstants.tooltipBrokenDownDataOptions(this.state.activeChart),
    });

    getChartDataForSeries = (data: any[]) => {
        const activeChart = (this.state && this.state.activeChart) || defaultActiveChart;
        const chartData: (number | null)[] = [];
        const { subunit } = currencyUtils.getActiveCurrencyData();
        const isMoneyChart = financialCharts.includes(activeChart);

        data.forEach((item) => {
            const formattedValue =
                item === null
                    ? null
                    : isMoneyChart
                      ? item[activeChart] / subunit
                      : item[activeChart];

            chartData.push(formattedValue);
        });

        return chartData;
    };

    getChartSelectors = () => (
        <Select
            className="selectors"
            defaultValue={this.state.activeChart || defaultActiveChart}
            onChange={this.onChartSelectChange}
        >
            {charts.map((key: chartKey) => (
                <Option key={key} value={key}>
                    {this.props.t(key)}
                </Option>
            ))}
        </Select>
    );

    onChartSelectChange = (value: chartKey) => this.setState(() => ({ activeChart: value }));

    getCurrenciesSelectors = () => {
        const { allCurrencies, activeCurrencies } = this.state;

        return (
            <div className="checkboxes">
                {allCurrencies.map((currency: string, index: number) => (
                    <Checkbox
                        key={currency}
                        value={currency}
                        className={'currency' + (index + 1)}
                        checked={activeCurrencies.includes(currency)}
                        onChange={(e: CheckboxChangeEvent) => this.onCurrencyChange(e)}
                    >
                        {currency}
                    </Checkbox>
                ))}
            </div>
        );
    };

    onCurrencyChange = (e: CheckboxChangeEvent) => {
        const { activeCurrencies } = this.state;
        const { value, checked } = e.target;
        const actualCurrencies: string[] = [...activeCurrencies];

        if (checked) {
            actualCurrencies.push(value);
        } else {
            if (actualCurrencies.length === 1) {
                return;
            }

            actualCurrencies.splice(actualCurrencies.indexOf(value), 1);
        }

        this.setState(() => ({ activeCurrencies: actualCurrencies }));
    };

    render = () => {
        const { t } = this.props;

        return (
            <ItemComponentWrapper titleKey={'trend_by_currency'} collapsible>
                <div className="chart brokenDown">
                    {this.getChartSelectors()}
                    <div className="axis-names">
                        <div>{t(this.state.activeChart)}</div>
                    </div>
                    <ReactEcharts notMerge option={this.getOptions()} />
                    {this.getCurrenciesSelectors()}
                </div>
            </ItemComponentWrapper>
        );
    };
}

export default withTranslation()(CasinoBrokenDownDataChart);
