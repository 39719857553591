import { Dispatch } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import '../../PromotionsStyles.scss';
import { getAwardedPromotions } from '../../../../redux/actions/backoffice/promotions-actions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ContentComponent } from '../../../../Components/ContentComponent/ContentComponent';
import { HeaderTitleComponent } from '../../../../Components/ContentComponent/HeaderTitleComponent';
import { Select, DatePicker, Space, Table, Button } from 'antd';
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
    getAwardedPromotionsData,
    getPromotionsErrorData,
    getPromotionsLoadingStateData,
    getTotalAwardedPromotionsAmountData,
    shouldUpdateData,
} from '../../../../redux/selectors/backoffice/propmotions-selectors';
import {
    ICurrencyData,
    IGameItemData,
    IPaginationData,
    IPromotionData,
} from '../../../../helpers/interfaces';
import Search from 'antd/es/input/Search';
import PromotionsColumns from './PromotionsColumns';
import { Link } from 'react-router-dom';
import { localStorageHelper } from '../../../../utils';
import { getGameConfigurationsByUser } from '../../../../redux/selectors/user-selectors';
import {
    getAllGameConfigurationsByUser,
    getAllGamesByUser,
} from '../../../../redux/actions/user-actions';

const { RangePicker } = DatePicker;

type SortOrder = 'DESCENDING' | 'ASCENDING';

interface SortConfig {
    sortKey: string;
    sortOrder: SortOrder;
}

const DEFAULT_SORT_CONFIG: SortConfig = { sortKey: 'id', sortOrder: 'DESCENDING' };
const DEFAULT_AMOUNT_OF_ITEMS_PER_PAGE = 20;
const SEARCH_OPTIONS = ['name', 'reference', 'gameId'];
const STATUS_OPTIONS = [
    { value: 'UPCOMING', label: 'Upcoming' },
    { value: 'ONGOING', label: 'Ongoing' },
    { value: 'ENDED', label: 'Ended' },
    { value: 'DISABLED', label: 'Disabled' },
];
const DEFAULT_PAGINATION = { pageNumber: 1, pageSize: DEFAULT_AMOUNT_OF_ITEMS_PER_PAGE };

interface IProps {
    getPromotions: (params: any) => void;
    data: IPromotionData[];
    totalAmount: number;
    isLoading: boolean;
    error: string;
    shouldUpdateData: boolean;
}

interface IState {
    filterKey: string;
    filterString: string;
    dateRange: [string, string];
    sortConfig: SortConfig;
    paginationData: IPaginationData;
    isGamesListLoaded: boolean;
}

const AwardedPromotions: React.FC<IProps> = ({ getPromotions, data, totalAmount, isLoading }) => {
    const { t } = useTranslation();
    const games = useSelector(getGameConfigurationsByUser);
    const dispatch = useDispatch();
    const [tableHeight, setTableHeight] = useState(0);
    const [state, setState] = useState({
        filterKey: SEARCH_OPTIONS[0],
        filterString: '',
        dateRange: ['', ''],
        sortConfig: DEFAULT_SORT_CONFIG,
        paginationData: DEFAULT_PAGINATION,
        isGamesListLoaded: false,
        status: '',
        currency: '',
    });

    const calculateTableHeight = useCallback(() => {
        const height = window.innerHeight * 0.8 - 194;
        setTableHeight(height);
    }, []);

    useEffect(() => {
        calculateTableHeight();
        window.addEventListener('resize', calculateTableHeight);

        return () => window.removeEventListener('resize', calculateTableHeight);
    }, [calculateTableHeight]);

    const canUserManageCampaigns = localStorageHelper
        .getChainedValue('user.authorities')
        .includes('MANAGE_CAMPAIGNS');
    const allCurrencies = localStorageHelper.getChainedValue('user.allCurrencies');

    const handlePagination = useCallback(
        (pageNumber: number, pageSize: number = state.paginationData.pageSize) =>
            setState((prevState) => ({
                ...prevState,
                paginationData: { pageNumber, pageSize },
            })),
        [state.paginationData.pageSize],
    );

    const requestPromotions = useCallback(() => {
        setState((prevState) => {
            const {
                paginationData,
                sortConfig,
                filterString,
                dateRange,
                status,
                currency,
                filterKey,
            } = prevState;

            getPromotions({
                paginationData,
                sortConfig,
                filterString,
                filterKey: filterKey === 'reference' ? 'externalCampaignId' : filterKey,
                dateRange,
                status,
                currency,
            });

            return prevState;
        });
    }, [getPromotions]);
    const columns = PromotionsColumns(canUserManageCampaigns, requestPromotions, dispatch);

    useEffect(() => {
        if (!state.isGamesListLoaded) {
            dispatch(getAllGameConfigurationsByUser());
            dispatch(getAllGamesByUser());
        }
    }, [dispatch, state.isGamesListLoaded]);

    useEffect(() => {
        requestPromotions();
    }, [
        requestPromotions,
        state.filterKey,
        state.filterString.length,
        state.dateRange,
        state.status,
        state.currency,
        state.paginationData,
    ]);

    useEffect(() => {
        if (games?.length > 0) setState((prevState) => ({ ...prevState, isGamesListLoaded: true }));
    }, [games]);

    const getData = useMemo(() => {
        const allGamesWithFreeRounds = games.filter(
            (game: IGameItemData) => game.freeRoundsEnabled,
        );

        const getGamesColumnContent = (games: { id: number; name: string }[]) => {
            if (games.length === allGamesWithFreeRounds.length) return t('all_games');
            if (games.length === 1) return games[0].name;
            return `${games.length} ${games.length % 10 === 1 ? t('game') : t('games')}`;
        };

        return (data || []).map((item) => ({
            ...item,
            key: item.id,
            gamesDisplay: getGamesColumnContent(item.games),
            games: item.games,
        }));
    }, [data, t, games]);

    const onTableChange = useCallback(
        (pagination: any, filters: any, sorter: any) => {
            handlePagination(
                pagination.current ?? 1,
                pagination.pageSize ?? DEFAULT_PAGINATION.pageSize,
            );

            const newSortConfig = Array.isArray(sorter)
                ? sorter[0]?.order
                    ? {
                          sortKey: sorter[0].field.toString(),
                          sortOrder: sorter[0].order === 'ascend' ? 'ASCENDING' : 'DESCENDING',
                      }
                    : DEFAULT_SORT_CONFIG
                : sorter?.field && sorter?.order
                  ? {
                        sortKey: sorter.field.toString(),
                        sortOrder: sorter.order === 'ascend' ? 'ASCENDING' : 'DESCENDING',
                    }
                  : DEFAULT_SORT_CONFIG;

            setState((prevState: any) => ({ ...prevState, sortConfig: newSortConfig }));
        },
        [handlePagination],
    );

    const handleFilterChange = (key: keyof IState, value: any) =>
        setState((prev) => ({ ...prev, [key]: value }));
    const handleStatusChange = (value: string) => setState((prev) => ({ ...prev, status: value }));
    const handleCurrencyChange = (value: string) =>
        setState((prev) => ({ ...prev, currency: value }));

    const totalWidth: number = columns.reduce(
        (total: number, col: any) => total + (col.width || 0),
        0,
    );

    return (
        <ContentComponent
            header={
                <>
                    <div className="flex justify-between items-end">
                        <HeaderTitleComponent
                            title={t('awarded_feature_promotions')}
                            customBreadcrumbs={<div>{t('all')}</div>}
                        />
                        {canUserManageCampaigns && (
                            <Link to="/casino/awarded-promotion/new">
                                <Button
                                    size="large"
                                    shape="round"
                                    type="primary"
                                    icon={<PlusCircleOutlined />}
                                >
                                    Create
                                </Button>
                            </Link>
                        )}
                    </div>
                    <div className="flex mt-2">
                        <Space.Compact
                            className="promotions-search__wrapper"
                            style={{ marginTop: '8px', marginRight: '16px' }}
                        >
                            <RangePicker
                                style={{ width: '300px' }}
                                className="promotions-search__datepicker"
                                onChange={(date) =>
                                    handleFilterChange(
                                        'dateRange',
                                        date ? [date[0], date[1]] : ['', ''],
                                    )
                                }
                                placeholder={['Starts from', 'Ends before']}
                            />
                        </Space.Compact>
                        <Space.Compact
                            className="promotions-search__wrapper"
                            style={{ marginTop: '8px', marginRight: '16px' }}
                        >
                            <Select
                                className="promotions-search-selector"
                                onChange={handleStatusChange}
                                style={{ width: '150px' }}
                                allowClear
                                placeholder={'Status'}
                            >
                                {STATUS_OPTIONS.map((status) => (
                                    <Select.Option key={status.value} value={status.value}>
                                        {status.label}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Select
                                className="promotions-search-selector"
                                onChange={handleCurrencyChange}
                                style={{ width: '150px' }}
                                allowClear
                                placeholder={'Currency'}
                                showSearch
                            >
                                {allCurrencies.map((currency: ICurrencyData) => (
                                    <Select.Option key={currency.id} value={currency.code}>
                                        {currency.code}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Space.Compact>
                        <Space.Compact
                            className="promotions-search__wrapper"
                            style={{ marginTop: '8px', width: '100%' }}
                        >
                            <Select
                                className="promotions-search-selector"
                                value={state.filterKey}
                                onChange={(value) => handleFilterChange('filterKey', value)}
                                style={{ width: '150px' }}
                            >
                                {SEARCH_OPTIONS.map((key) => (
                                    <Select.Option key={key} value={key}>
                                        {t(key)}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Search
                                placeholder={t('enter_search_value')}
                                value={state.filterString}
                                onChange={(e) => handleFilterChange('filterString', e.target.value)}
                                suffix={
                                    <CloseCircleOutlined
                                        className={`promotions-search__clear${state.filterString.length > 0 ? '__visible' : ''}`}
                                        onClick={() => handleFilterChange('filterString', '')}
                                    />
                                }
                            />
                        </Space.Compact>
                    </div>
                </>
            }
            innerContent={
                <Table
                    pagination={{
                        pageSize: state.paginationData.pageSize,
                        current: state.paginationData.pageNumber,
                        total: totalAmount,
                    }}
                    style={{
                        minWidth: '100%',
                        paddingBottom: '16px',
                    }}
                    scroll={{ x: totalWidth, y: tableHeight }}
                    dataSource={getData}
                    className="ant-temporary-style-fix-for-table"
                    columns={columns}
                    expandable={{
                        showExpandColumn: true,
                        expandedRowRender: (record) => (
                            <>
                                <p>{t('Games in campaign:')}</p>
                                <ul>
                                    {record.games.map((game) => (
                                        <li key={game.id}>
                                            {game.name} | ID: rp-{game.id}
                                        </li>
                                    ))}
                                </ul>
                                <p>
                                    {`${t('Game IDs: ')}${record.games
                                        .map((game) => game.id)
                                        .join(', ')}`}
                                </p>
                            </>
                        ),
                        columnWidth: 40,
                    }}
                    loading={isLoading}
                    size="small"
                    onChange={onTableChange}
                    footer={() => null}
                />
            }
        />
    );
};

const mapStateToProps = (state: any) => ({
    data: getAwardedPromotionsData(state),
    totalAmount: getTotalAwardedPromotionsAmountData(state),
    isLoading: getPromotionsLoadingStateData(state),
    error: getPromotionsErrorData(state),
    shouldUpdateData: shouldUpdateData(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getPromotions: (data: any) => dispatch(getAwardedPromotions(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AwardedPromotions);
