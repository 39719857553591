import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Select, Tooltip } from 'antd';
import { currencyUtils, getAbbreviatedValue, localStorageHelper } from 'utils';
import ItemComponentWrapper from '../../../../Components/ContentComponent/ItemComponentWrapper';
import { FreeRoundType } from 'utils';

interface IProps {
    data: any;
    onCurrencyChange?: Function;
    currentCurrencyCode: string;
}

const PromotionalTotalComponent: React.FC<IProps & WithTranslation> = ({
    data,
    onCurrencyChange,
    currentCurrencyCode,
    t,
}) => {
    const userCurrencyData = currencyUtils.getActiveCurrencyData();
    const isUserCurrencyBillable = userCurrencyData.billable;
    const selectOptions = isUserCurrencyBillable
        ? [
              {
                  key: 'option-1',
                  value: 'user',
                  label: 'User Currency',
              },
              {
                  key: 'option-2',
                  value: 'campaign',
                  label: 'Campaign Currency',
              },
          ]
        : [
              {
                  key: 'option-2',
                  value: 'campaign',
                  label: 'Campaign Currency',
              },
          ];
    const defaultSelectValue = isUserCurrencyBillable ? 'user' : 'campaign';
    const isPrimaryFlow =
        localStorageHelper.getChainedValue('user.brandFeatures.freeRoundsType') ===
        FreeRoundType.PRIMARY_FLOW;

    const setTooltipToTitle = (title: string, tooltip: string, index: number) => (
        <Tooltip title={tooltip} id={`tooltip_${index}`}>
            <span>{title}</span>
        </Tooltip>
    );

    const handleCurrencyChange = (value: any) => {
        if (onCurrencyChange) {
            onCurrencyChange(value);
        }
    };

    const isPlayerListStrategy = data.strategy === 'PLAYER_LIST';

    return (
        <>
            {data ? (
                <ItemComponentWrapper titleKey="grand_total" collapsible>
                    <div className="grand-total">
                        <div className="grand-total__cell total-cell">
                            <p className="total-cell__title">
                                {setTooltipToTitle(
                                    `${t('totalPlayers')}${
                                        !isPlayerListStrategy ? ` / ${t('eligible_players')}` : ''
                                    }`,
                                    `${isPrimaryFlow ? 'Total number of active players' : 'The number of players who finished the campaign'}${
                                        !isPlayerListStrategy ? ' / Number of eligible players' : ''
                                    }`,
                                    1,
                                )}
                            </p>
                            <p className="total-cell__content">
                                {isPrimaryFlow ? data.totalPlayers : data.playedPlayersNumber}
                                {!isPlayerListStrategy ? ' / ' + data.eligiblePlayersNumber : ''}
                            </p>
                        </div>
                        <div className="grand-total__cell total-cell">
                            <p className="total-cell__title">
                                {setTooltipToTitle(t('totalBet'), 'Total bet amount', 3)}
                            </p>
                            <p className="total-cell__content">
                                {getAbbreviatedValue(
                                    data.totalBet,
                                    currentCurrencyCode,
                                    true,
                                    true,
                                )}{' '}
                                <span className="total-cell__currency">{currentCurrencyCode}</span>
                            </p>
                        </div>
                        <div className="grand-total__cell total-cell">
                            <p className="total-cell__title">
                                {setTooltipToTitle(t('totalWin'), 'Total win amount', 4)}
                            </p>
                            <p className="total-cell__content">
                                {getAbbreviatedValue(
                                    isPrimaryFlow ? data.totalWin : data.totalCompletedWin,
                                    currentCurrencyCode,
                                    true,
                                    true,
                                )}{' '}
                                <span className="total-cell__currency">{currentCurrencyCode}</span>
                            </p>
                        </div>
                        <div className="grand-total__cell total-cell">
                            <p className="total-cell__title">
                                {setTooltipToTitle(
                                    `${t('playedFreeRounds')}${
                                        !isPlayerListStrategy ? ` / ${t('promoted_rounds')}` : ''
                                    }`,
                                    `Total played free rounds${
                                        !isPlayerListStrategy ? '/ Number of bet rounds' : ''
                                    }`,
                                    5,
                                )}
                            </p>
                            <p className="total-cell__content">
                                {data.totalPlayedFreeRounds}
                                {!isPlayerListStrategy
                                    ? ' / ' + data.betNumber * data.eligiblePlayersNumber
                                    : ''}
                            </p>
                        </div>
                        <div className="grand-total__cell total-cell">
                            <Select
                                defaultValue={defaultSelectValue}
                                onChange={handleCurrencyChange}
                                options={selectOptions}
                                disabled={!isUserCurrencyBillable}
                            />
                        </div>
                    </div>
                </ItemComponentWrapper>
            ) : null}
        </>
    );
};

export default withTranslation()(PromotionalTotalComponent);
