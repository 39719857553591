import { useDispatch, useSelector } from 'react-redux';
import {
    getGamesByPriceAreLoadingData,
    getGamesByPriceData,
} from '../../../../redux/selectors/backoffice/propmotions-selectors';
import React, { useEffect, useMemo, useState } from 'react';
import { currencyUtils, localStorageHelper } from '../../../../utils';
import FormikField from './FormikField';
import {
    CheckCircleOutlined,
    DeleteOutlined,
    LoadingOutlined,
    PlusCircleOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Divider, Input, List, Modal, Table, TableProps, Tooltip } from 'antd';
import { getGamesByPromotionPrice } from '../../../../redux/actions/backoffice/promotions-actions';
import Search from 'antd/es/input/Search';
import { debounce } from 'lodash';

interface AddGamesModalProps {
    formik: any;
    visible: boolean;
    onClose: () => void;
    availableGames: any;
    onAddGames: (selectedGames: number[]) => void;
}

const AddGamesModal: React.FC<AddGamesModalProps> = ({
    formik,
    visible,
    onClose,
    availableGames,
    onAddGames,
}) => {
    const [selectedGames, setSelectedGames] = useState<number[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const handleSelectGame = (gameId: number) => {
        setSelectedGames((prev) =>
            prev.includes(gameId) ? prev.filter((id) => id !== gameId) : [...prev, gameId],
        );
    };

    const handleAddGames = () => {
        onAddGames(selectedGames);
        setSelectedGames([]);
        onClose();
    };

    const filteredGames = availableGames
        .filter((game: any) => !formik.values.gameIds.includes(game.gameId))
        .filter(
            (game: any) =>
                game.gameName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                game.gameId.toString().includes(searchQuery),
        );

    return (
        <Modal
            title="Add Games"
            open={visible}
            onCancel={() => {
                setSearchQuery('');
                setSelectedGames([]);
                onClose();
            }}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button
                    key="add"
                    type="primary"
                    onClick={handleAddGames}
                    disabled={selectedGames.length === 0}
                >
                    Add Games
                </Button>,
            ]}
        >
            <Input
                placeholder="Search by Game Name or ID"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="mb-4"
            />
            <div className="mb-2">
                <Checkbox
                    indeterminate={
                        selectedGames.length > 0 && selectedGames.length < filteredGames.length
                    }
                    onChange={(e) =>
                        setSelectedGames(
                            e.target.checked ? filteredGames.map((g: any) => g.gameId) : [],
                        )
                    }
                    checked={selectedGames.length === filteredGames.length}
                >
                    Selected: {selectedGames.length} of {filteredGames.length}
                </Checkbox>
            </div>
            <List
                dataSource={filteredGames}
                renderItem={(game: any) => (
                    <List.Item>
                        <Checkbox
                            checked={selectedGames.includes(game.gameId)}
                            onChange={() => handleSelectGame(game.gameId)}
                        >
                            {game.gameId} {game.gameName}
                        </Checkbox>
                    </List.Item>
                )}
                style={{ maxHeight: '400px', overflowY: 'scroll' }}
            />
        </Modal>
    );
};

const GamesTab = ({ formik }: any) => {
    const dispatch = useDispatch();
    const gamesByPrice: any = useSelector(getGamesByPriceData);
    const gamesByPriceAreLoading: boolean = useSelector(getGamesByPriceAreLoadingData);
    const currencyList = useMemo(() => {
        const allCurrencies = localStorageHelper.getChainedValue('user.currenciesByBrand') || [];

        return allCurrencies.map((value: any) => ({
            label: value.code,
            value: value.code,
        }));
    }, []);
    const [availableGames, setAvailableGames] = useState<any[]>([]);
    const [addGamesModalVisible, setAddGamesModalVisible] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [editConfirmationVisible, setEditConfirmationVisible] = useState(false);
    const [suggestedPrices, setSuggestedPrices] = useState<number[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredGames, setFilteredGames] = useState(availableGames);

    const mappedGames = useMemo(() => {
        return availableGames.filter((game) => formik.values.gameIds.includes(game.gameId));
    }, [formik.values.gameIds, availableGames]);

    const handleAddGames = (gameIds: number[]) => {
        formik.setFieldValue('gameIds', [...formik.values.gameIds, ...gameIds]);
    };

    const rowSelection: TableProps<any>['rowSelection'] = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(newSelectedRowKeys);
        },
    };

    const handleRemoveSelectedGames = () => {
        const remainingGames = formik.values.gameIds.filter(
            (gameId: number) => !selectedRowKeys.includes(gameId),
        );
        formik.setFieldValue('gameIds', remainingGames);
        setSelectedRowKeys([]);
    };

    const handleCancelEdit = () => {
        setEditConfirmationVisible(false);
    };

    const handleConfirmEdit = () => {
        formik.setFieldValue('gameIds', []);
        setEditConfirmationVisible(false);
    };

    const costPerPlayer = useMemo(() => {
        const { price, number_of_features, subunit } = formik.values;

        if (price && number_of_features) {
            return number_of_features * (price / subunit);
        }

        return 0;
    }, [formik.values]);

    const totalPlayers = useMemo(() => {
        const { strategy, number_of_players, players } = formik.values;

        if (strategy === 'FIRST_N_PLAYERS' && number_of_players) {
            return number_of_players;
        }
        if (strategy === 'PLAYER_LIST' && players?.length > 0) {
            return players.length;
        }

        return 0;
    }, [formik.values]);

    const calculatedTotalCost = useMemo(() => {
        return costPerPlayer * totalPlayers;
    }, [costPerPlayer, totalPlayers]);

    const handleSearch = (value: string) => {
        setSearchTerm(value);
        const lowercasedValue = value.toLowerCase();
        const filtered = mappedGames.filter((game: any) =>
            game.gameName.toLowerCase().includes(lowercasedValue),
        );
        setFilteredGames(filtered);
    };

    useEffect(() => {
        setSuggestedPrices([]);

        if (!formik.values.price || gamesByPriceAreLoading || !gamesByPrice) return;
        setSuggestedPrices([]);
        setAvailableGames([]);

        const matchedGames = gamesByPrice[Number(formik.values.price)];

        if (matchedGames) {
            setAvailableGames(matchedGames);
        } else {
            const prices = Object.keys(gamesByPrice)
                .map(Number)
                .sort((a, b) => a - b);

            setSuggestedPrices(prices);
        }
    }, [formik.values.price, gamesByPriceAreLoading, gamesByPrice]);

    const debouncedFetchGames = useMemo(
        () =>
            debounce((currency, price) => {
                const currencyId = currencyUtils.getCurrencyDataByCode(currency).id;
                dispatch(getGamesByPromotionPrice({ currencyId, price }));
            }, 500),
        [dispatch],
    );

    useEffect(() => {
        if (formik.values.price > 0) {
            debouncedFetchGames(formik.values.currency, formik.values.price);
        }
    }, [formik.values.price, formik.values.currency, debouncedFetchGames]);

    useEffect(() => {
        const newMappedGames = formik.values.gameIds
            .map((id: number) => availableGames.find((game) => game.gameId === id))
            .filter(Boolean);

        if (JSON.stringify(newMappedGames) !== JSON.stringify(formik.values.mappedGames)) {
            formik.setFieldValue('mappedGames', newMappedGames);
        }
    }, [formik.values.gameIds, availableGames, formik]);

    useEffect(() => {
        setFilteredGames(mappedGames);
    }, [mappedGames]);

    return (
        <>
            <div className="flex">
                <FormikField
                    name="currency"
                    category="currencySelect"
                    label="Currency"
                    options={currencyList}
                    className="w-full max-w-48 mr-6"
                    placeholder="Currency"
                    disabled={formik.values.gameIds.length > 0}
                />
                <FormikField
                    name="price"
                    category="price"
                    label="Feature Cost"
                    className="w-full max-w-48"
                    disabled={formik.values.gameIds.length > 0}
                />
                <Modal
                    title="Configuration Change"
                    open={editConfirmationVisible}
                    onCancel={handleCancelEdit}
                    footer={[
                        <Button key="cancel" onClick={handleCancelEdit}>
                            Cancel
                        </Button>,
                        <Button key="confirm" danger onClick={handleConfirmEdit}>
                            Yes, change configuration
                        </Button>,
                    ]}
                >
                    <p>
                        Changing the Currency or Feature Cost will{' '}
                        <span className="font-bold">
                            reset any games you have already selected.
                        </span>
                    </p>
                    <p className="mt-3">This action cannot be undone. Do you want to proceed?</p>
                </Modal>
                {gamesByPriceAreLoading && (
                    <div className="flex items-end justify-center w-10 h-10 self-end mb-2">
                        <LoadingOutlined style={{ fontSize: '28px' }} />
                    </div>
                )}
                {formik.values.gameIds.length > 0 && (
                    <div className="flex space-x-4 items-end ml-4">
                        <Button size="large" onClick={() => setEditConfirmationVisible(true)}>
                            Edit
                        </Button>
                    </div>
                )}
                {availableGames.length > 0 && !gamesByPriceAreLoading && (
                    <div className="flex ml-4 items-end">
                        <CheckCircleOutlined
                            style={{ fontSize: '30px', color: 'lightgreen', marginBottom: '6px' }}
                        />
                    </div>
                )}
                {suggestedPrices.length > 0 && (
                    <div className="flex space-x-4 items-end ml-4">
                        {suggestedPrices.map((price: number) => (
                            <Button
                                size="large"
                                key={price}
                                onClick={() => {
                                    formik.setFieldValue('price', Number(price));
                                    setSuggestedPrices([]);
                                }}
                            >
                                {Number(price) / formik.values.subunit} {formik.values.currency}
                            </Button>
                        ))}
                    </div>
                )}
            </div>
            <Divider />
            <div className="flex">
                <FormikField
                    name="number_of_features"
                    category="input"
                    label="Number of Features"
                    className="w-full max-w-48 mr-6"
                />
            </div>
            <div className="mt-6 bg-gray-50 rounded w-96 p-3">
                <div className="flex items-center justify-between">
                    <span className="font-medium text-gray-600">
                        Cost Per Player{' '}
                        <Tooltip title="The cost per player for this feature.">
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </span>
                    <span className="font-medium text-gray-900">
                        {costPerPlayer}
                        {'  '}
                        {formik.values.currency}
                    </span>
                </div>
                <div className="mt-2 flex items-center justify-between">
                    <span className="font-medium text-gray-600">Total Players</span>
                    <span className="font-medium text-gray-900">{totalPlayers}</span>
                </div>
                <Divider className="mb-3 mt-3" />
                <div className="flex items-center justify-between">
                    <span className="font-medium text-gray-600">
                        Calculated Total Cost{' '}
                        <Tooltip title="The total cost calculated based on the number of features and players.">
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </span>
                    <span className="font-medium text-gray-900">
                        {calculatedTotalCost}
                        {'  '}
                        {formik.values.currency}
                    </span>
                </div>
            </div>
            <Divider />
            <div>
                <h3 className="font-medium text-lg mb-4">Games</h3>
                <p className="text-sm text-gray-500 mb-4">
                    {availableGames.length > 0
                        ? 'Choose the games where this feature will run'
                        : 'Choose the Currency and Feature Cost first'}
                </p>
                {formik.values.gameIds.length > 0 ? (
                    <>
                        <div className="flex w-full justify-between">
                            <Search
                                className="w-1/2"
                                placeholder="Search games"
                                onSearch={handleSearch}
                                onChange={(e) => handleSearch(e.target.value)}
                                value={searchTerm}
                                allowClear
                            />
                            <div className="flex w-1/2 justify-end">
                                {selectedRowKeys.length > 0 && (
                                    <Button
                                        onClick={handleRemoveSelectedGames}
                                        className="mr-2"
                                        icon={<DeleteOutlined />}
                                    >
                                        Remove Selected
                                    </Button>
                                )}
                            </div>
                        </div>
                        <Divider className="mb-2 mt-4" />
                        <Button
                            icon={<PlusCircleOutlined />}
                            type="text"
                            onClick={() => setAddGamesModalVisible(true)}
                        >
                            Add Game
                        </Button>
                        <Divider className="mb-0 mt-2" />
                        <Table
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection,
                            }}
                            size="small"
                            columns={[
                                {
                                    title: 'Game Name',
                                    dataIndex: 'gameName',
                                    key: 'gameName',
                                    width: 330,
                                },
                                {
                                    title: 'Game ID',
                                    dataIndex: 'gameId',
                                    key: 'gameId',
                                    width: 110,
                                },
                                {
                                    title: 'Multiplier',
                                    dataIndex: 'priceMultiplier',
                                    key: 'priceMultiplier',
                                    width: 110,
                                    render: (record: any) => {
                                        return `x${record}`;
                                    },
                                },
                                {
                                    title: 'Bet',
                                    dataIndex: 'bet',
                                    key: 'bet',
                                    render: (record: any) => {
                                        return `${record / currencyUtils.getCurrencyDataByCode(formik.values.currency).subunit} ${formik.values.currency}`;
                                    },
                                },
                            ]}
                            dataSource={filteredGames}
                            rowKey="gameId"
                            pagination={{
                                pageSize: 5,
                                showSizeChanger: true,
                            }}
                        />
                    </>
                ) : (
                    <Button
                        type="dashed"
                        icon={<PlusOutlined />}
                        className="flex items-center"
                        disabled={availableGames.length === 0}
                        onClick={() => setAddGamesModalVisible(true)}
                    >
                        Add Games
                    </Button>
                )}
                <AddGamesModal
                    formik={formik}
                    visible={addGamesModalVisible}
                    onClose={() => setAddGamesModalVisible(false)}
                    availableGames={availableGames}
                    onAddGames={handleAddGames}
                />
            </div>
        </>
    );
};

export default GamesTab;
