import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import { localStorageHelper, Messages } from '../../../../utils';
import { Alert, Button, Divider, Input, Modal, Table, TableProps, Upload } from 'antd';
import FormikField from './FormikField';
import {
    DeleteOutlined,
    PlusCircleOutlined,
    PlusOutlined,
    TeamOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import Search from 'antd/es/input/Search';

const PlayersTab = ({ formik }: any) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isAddPlayerModalVisible, setIsAddPlayerModalVisible] = useState(false);
    const [newPlayerId, setNewPlayerId] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPlayers, setFilteredPlayers] = useState(formik.values.players || []);
    const [csvSummary, setCsvSummary] = useState<{ total: number; duplicates: number } | null>(
        null,
    );
    const [csvAlertVisible, setCsvAlertVisible] = useState(false);
    const [csvErrorAlert, setCsvErrorAlert] = useState<string | null>(null);
    const [csvErrorAlertVisible, setCsvErrorAlertVisible] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5,
    });

    const maxPlayersInList =
        localStorageHelper.getChainedValue('user.brandFeatures.promotionLimits')
            ?.maxPlayersPerPlayerListCampaign || 20000;

    const handleTableChange = (newPagination: any) => {
        setPagination(newPagination);
    };

    const handleCSVParse = (file: File) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const csvData = e.target?.result;
            if (typeof csvData === 'string') {
                setCsvSummary(null);
                setCsvAlertVisible(false);
                setCsvErrorAlert(null);
                setCsvErrorAlertVisible(false);

                Papa.parse(csvData, {
                    header: false,
                    skipEmptyLines: true,
                    complete: (results) => {
                        const rows: any[] = results.data.map((row: any) => row[0]);

                        if (rows.length === 0) {
                            setCsvErrorAlert(
                                'The uploaded file is empty. Please upload a valid file.',
                            );
                            setCsvErrorAlertVisible(true);
                            return;
                        }

                        if (isNaN(rows[0]) && rows.length > 1) {
                            rows.shift();
                        }

                        if (rows.length > maxPlayersInList) {
                            setCsvErrorAlert(
                                `The uploaded file contains more than ${maxPlayersInList} rows. Please upload a smaller file.`,
                            );
                            setCsvErrorAlertVisible(true);
                            return;
                        }

                        const currentPlayers = formik.values.players || [];
                        const newRows = rows.filter(
                            (row) => typeof row === 'string' && !currentPlayers.includes(row),
                        );

                        const totalRows = rows.length;
                        const duplicateCount = totalRows - newRows.length;

                        setCsvSummary({
                            total: totalRows,
                            duplicates: duplicateCount,
                        });
                        setCsvAlertVisible(true);
                        formik.setFieldValue('players', [...currentPlayers, ...newRows]);
                    },
                    error: () => {
                        setCsvErrorAlert('Error parsing CSV file. Please try again.');
                        setCsvErrorAlertVisible(true);
                    },
                });
            }
        };
        reader.readAsText(file);
    };

    const uploadProps = {
        name: 'file',
        accept: '.csv',
        multiple: false,
        showUploadList: false,
        beforeUpload: (file: File) => {
            const isCSV = file.type === 'text/csv' || file.name.endsWith('.csv');
            if (!isCSV) {
                Messages.error('Invalid file type. Please upload a CSV file.');
                return false;
            }
            handleCSVParse(file);
            return false;
        },
        onDrop: (e: React.DragEvent<HTMLDivElement>) => {
            Array.from(e.dataTransfer.files).forEach((file) => {
                const isCSV = file.type === 'text/csv' || file.name.endsWith('.csv');
                if (!isCSV) {
                    Messages.error('Invalid file type. Please upload a CSV file.');
                    return;
                }
                handleCSVParse(file);
            });
        },
    };

    const rowSelection: TableProps<any>['rowSelection'] = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(newSelectedRowKeys);
        },
    };

    const handleRemoveSelectedPlayers = () => {
        const remainingPlayers = formik.values.players.filter(
            (_: string, index: number) => !selectedRowKeys.includes(index),
        );
        formik.setFieldValue('players', remainingPlayers);
        setSelectedRowKeys([]);
    };

    const handleAddPlayer = () => {
        if (!newPlayerId.trim()) {
            return;
        }
        const currentPlayers = formik.values.players || [];

        if (currentPlayers.includes(newPlayerId.trim())) {
            Messages.error('Player ID already exists.');
            return;
        }

        formik.setFieldValue('players', [...currentPlayers, newPlayerId.trim()]);
        setNewPlayerId('');
        setIsAddPlayerModalVisible(false);
    };

    const handleSearch = (value: string) => {
        setSearchTerm(value);
        const lowercasedValue = value.toLowerCase();
        const filtered = (formik.values.players || []).filter((player: string) =>
            player.toLowerCase().includes(lowercasedValue),
        );
        setFilteredPlayers(filtered);
    };

    useEffect(() => {
        setFilteredPlayers(formik.values.players || []);
    }, [formik.values.players]);

    return (
        <>
            <div className="space-y-8">
                <div className="flex flex-col space-y-4">
                    <FormikField
                        name="strategy"
                        category="strategy"
                        label="Strategy for campaign"
                    />
                </div>
            </div>

            {formik.values.strategy === 'PLAYER_LIST' && (
                <>
                    <Modal
                        title="Add Player"
                        open={isAddPlayerModalVisible}
                        onCancel={() => {
                            setNewPlayerId('');
                            setIsAddPlayerModalVisible(false);
                        }}
                        footer={[
                            <Button
                                key="cancel"
                                onClick={() => {
                                    setNewPlayerId('');
                                    setIsAddPlayerModalVisible(false);
                                }}
                            >
                                Cancel
                            </Button>,
                            <Button key="submit" type="primary" onClick={handleAddPlayer}>
                                Add Player
                            </Button>,
                        ]}
                    >
                        <label htmlFor="playerId">Player ID</label>
                        <Input
                            id="playerId"
                            size="large"
                            value={newPlayerId}
                            onChange={(e) => setNewPlayerId(e.target.value)}
                            placeholder="Enter Player ID"
                        />
                    </Modal>
                    {csvErrorAlertVisible && (
                        <Alert
                            message="File Upload Failed"
                            description={csvErrorAlert}
                            type="error"
                            showIcon
                            className="mb-4 mt-4"
                            closable
                            onClose={() => setCsvErrorAlertVisible(false)}
                        />
                    )}
                    {formik.values.players.length === 0 ? (
                        <>
                            <Upload.Dragger {...uploadProps} className="flex mt-4">
                                <div className="flex w-full items-center justify-center mt-6">
                                    <div className="flex items-center justify-center w-16 h-16 rounded-full bg-gray-200">
                                        <TeamOutlined className="text-3xl text-gray-500" />
                                    </div>
                                </div>

                                <div className="text-center">
                                    <p className="text-lg font-semibold text-gray-700">
                                        No players yet
                                    </p>
                                    <p className="text-sm text-gray-500">
                                        Drag & drop your CSV file here, or click to browse local
                                        files
                                    </p>
                                </div>

                                <div className="flex w-full items-center justify-center mt-4 mb-6">
                                    <Button icon={<UploadOutlined />} color="black" className="">
                                        Import .CSV file
                                    </Button>
                                </div>
                            </Upload.Dragger>
                            <Button
                                onClick={() => setIsAddPlayerModalVisible(true)}
                                icon={<PlusOutlined />}
                                className="mt-4"
                            >
                                Or Add Players Manually
                            </Button>
                        </>
                    ) : (
                        <div className="mt-6">
                            {csvAlertVisible && csvSummary && (
                                <Alert
                                    message={`Rows Imported: ${csvSummary.total - csvSummary.duplicates} of ${csvSummary.total}`}
                                    description={`Duplicate Rows: ${csvSummary.duplicates}`}
                                    type="success"
                                    showIcon
                                    className="mb-4"
                                    closable
                                    onClose={() => setCsvAlertVisible(false)}
                                />
                            )}
                            <div className="flex w-full justify-between">
                                <Search
                                    className="w-1/2"
                                    placeholder="Search players"
                                    onSearch={handleSearch}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    value={searchTerm}
                                    allowClear
                                />
                                <div className="flex items-center justify-end w-1/2">
                                    {selectedRowKeys.length > 0 && (
                                        <Button
                                            onClick={handleRemoveSelectedPlayers}
                                            className="mr-2"
                                            icon={<DeleteOutlined />}
                                        >
                                            Remove Selected
                                        </Button>
                                    )}
                                    <Upload {...uploadProps}>
                                        <Button
                                            icon={<UploadOutlined />}
                                            color="black"
                                            className=""
                                        >
                                            Import .CSV file
                                        </Button>
                                    </Upload>
                                </div>
                            </div>
                            <Divider className="mb-2 mt-4" />
                            <Button
                                icon={<PlusCircleOutlined />}
                                type="text"
                                onClick={() => setIsAddPlayerModalVisible(true)}
                            >
                                Add Player
                            </Button>
                            <Divider className="mb-0 mt-2" />
                            <Table
                                rowSelection={{
                                    type: 'checkbox',
                                    ...rowSelection,
                                }}
                                size="small"
                                columns={[
                                    {
                                        title: 'Player ID',
                                        dataIndex: 'playerId',
                                        key: 'playerId',
                                    },
                                ]}
                                dataSource={filteredPlayers.map(
                                    (player: string, index: number) => ({
                                        key: index,
                                        playerId: player,
                                    }),
                                )}
                                pagination={{
                                    current: pagination.current,
                                    pageSize: pagination.pageSize,
                                    showSizeChanger: true,
                                    pageSizeOptions: ['5', '10', '20', '50'],
                                    onChange: (page, pageSize) =>
                                        setPagination({ current: page, pageSize }),
                                }}
                                onChange={handleTableChange}
                            />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default PlayersTab;
