import React from 'react';
import { Button, Modal } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import './TableFilter.scss';
import { CurrencySelector } from './TableFilterContent/CurrencySelector';
import * as i18next from 'i18next';
import { DeviceTypeSelector } from 'Components/Filters/TableFilterContent/DeviceTypeSelector';
import { IFilters } from 'helpers/interfaces';
import { FilterOutlined } from '@ant-design/icons/lib';
import GamesSelector from './TableFilterContent/GamesSelector';

interface IProp {
    t: i18next.TFunction;
    filters: IFilters;
    applyFilters: Function;
    cancelFilters: Function;
    onFiltersChangeCallback: Function;
    reportKey: string;
    context: any;
}

interface IState {
    visible: boolean;
    isChangeSomething: boolean;
}

class TableFilter extends React.Component<IProp & WithTranslation, IState> {
    state: IState = {
        visible: false,
        isChangeSomething: false,
    };

    applyingFilters = () => {
        if (!this.state.isChangeSomething) {
            return;
        }

        this.setState(() => ({ visible: false, isChangeSomething: false }));

        this.props.applyFilters();
        this.props.context.changeOverlaysState();
    };

    onChangeCallback = (changedField: keyof IFilters, value: string) => {
        this.setState(() => ({ isChangeSomething: true }));

        this.props.onFiltersChangeCallback(changedField, value);
    };

    showModal = () => {
        this.props.context.changeOverlaysState();
        this.setState(() => ({ visible: true }));
    };

    cancelFilters = () => {
        this.setState(() => ({ visible: false, isChangeSomething: false }));
        this.props.context.changeOverlaysState();
        this.props.cancelFilters();
    };

    render = () => {
        const { t, filters, reportKey } = this.props;
        const { isChangeSomething, visible } = this.state;
        const shouldDisplayCurrenciesFilter = ['players', 'casino', 'games'].includes(reportKey);
        const shouldDisplayDeviceFilter = ['games'].includes(reportKey);
        const shouldDisplayGamesFilter = ['playerProfile'].includes(reportKey);

        return (
            <>
                <Button onClick={this.showModal} icon={<FilterOutlined />}>
                    {t('table_tools_filter')}
                </Button>
                <Modal
                    destroyOnClose
                    title={t('table_tools_filter')}
                    open={visible}
                    okText={t('table_tools_apply')}
                    okType={isChangeSomething ? 'danger' : 'dashed'}
                    onOk={this.applyingFilters}
                    onCancel={this.cancelFilters}
                    wrapClassName="filter-modal-wrapper"
                >
                    {shouldDisplayCurrenciesFilter ? (
                        <CurrencySelector
                            t={t}
                            onChangeCallback={this.onChangeCallback}
                            filters={filters}
                        />
                    ) : null}
                    {shouldDisplayDeviceFilter ? (
                        <DeviceTypeSelector
                            t={t}
                            onChangeCallback={this.onChangeCallback}
                            filters={filters}
                        />
                    ) : null}
                    {shouldDisplayGamesFilter ? (
                        <GamesSelector
                            t={t}
                            onChangeCallback={this.onChangeCallback}
                            filters={filters}
                        />
                    ) : null}
                </Modal>
            </>
        );
    };
}

export default withTranslation()(TableFilter);
