import { ofType } from 'redux-observable';
import { IAction, ICountry } from '../../../helpers/interfaces';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { currencyUtils, getLoginRequestHeader, localStorageHelper } from '../../../utils';
import { from, Observable, of } from 'rxjs';
import {
    addPlayerToCampaignCsvSuccess,
    cloneCampaignSuccess,
    createAwardedPromotionSuccess,
    createPromotionSuccess,
    createPromotionWithCSVSuccess,
    deleteCampaignSuccess,
    editAwardedCampaignSuccess,
    editPromotionSuccess,
    editPromotionWithCSVSuccess,
    getAwardedCampaignPlayersSuccess,
    getAwardedCampaignSuccess,
    getAwardedPromotionsSuccess,
    getCampaignSuccess,
    getGamesByPromotionPriceSuccess,
    getPromotionsSuccess,
    importPlayersCsvSuccess,
    PromotionTypes,
    removePlayersFromCampaignSuccess,
    terminateCampaignSuccess,
    uploadCSVSuccess,
} from '../../actions/backoffice/promotions-actions';
import axios from 'axios';
import { apiError } from '../../actions/user-actions';
import { SERVER_URL } from '../../../utils/config';
import Papa from 'papaparse';
import FormData from 'form-data';

const countriesList =
    localStorageHelper
        .getChainedValue('user.countries')
        ?.map((country: ICountry) => country.alpha2Code) || [];

export function createBinaryCsvFile(players: string[]): Blob {
    if (!Array.isArray(players) || players.length === 0) {
        throw new Error("Invalid data: 'players' must be a non-empty array.");
    }

    const formattedPlayers = players.map((name) => [name]);

    try {
        const csvContent = Papa.unparse(formattedPlayers, { header: false });

        return new Blob([csvContent], { type: 'text/csv' });
    } catch (error) {
        throw new Error('Failed to create binary CSV file.');
    }
}

export const getPromotionsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.GET_FREE_ROUNDS_PROMOTIONS_REQUEST),
        mergeMap((action) => {
            const { paginationData, sortConfig, filterString, filterKey, dateRange } =
                action.payload;

            const params = new URLSearchParams();
            params.append('paging.pageNumber', paginationData.pageNumber.toString());
            params.append('paging.pageSize', paginationData.pageSize.toString());
            params.append('paging.sortBy', sortConfig.sortKey);
            params.append('paging.order', sortConfig.sortOrder);
            params.append('type', 'FREE_ROUNDS');

            if (filterString) {
                params.append(filterKey, filterString);
            }
            if (dateRange[0]) {
                params.append('startDateFrom', dateRange[0].format('YYYY-MM-DD'));
            }
            if (dateRange[1]) {
                params.append('startDateTo', dateRange[1].format('YYYY-MM-DD'));
            }

            const headers = getLoginRequestHeader('application/json');

            return from(
                axios.get(`${SERVER_URL}/api/v1/brand/current/campaign`, {
                    params: params,
                    headers: headers,
                }),
            ).pipe(
                map((response) => getPromotionsSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, PromotionTypes.GET_FREE_ROUNDS_PROMOTIONS_REQUEST)),
                ),
            );
        }),
    );

export const getAwardedPromotionsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.GET_AWARDED_PROMOTIONS_REQUEST),
        mergeMap((action) => {
            const {
                paginationData,
                sortConfig,
                filterString,
                filterKey,
                dateRange,
                currency,
                status,
            } = action.payload;

            const params = new URLSearchParams();
            params.append('paging.pageNumber', paginationData.pageNumber.toString());
            params.append('paging.pageSize', paginationData.pageSize.toString());
            params.append('paging.sortBy', sortConfig.sortKey);
            params.append('paging.order', sortConfig.sortOrder);
            params.append('type', 'AWARDED_FEATURE');

            if (filterString) {
                params.append(filterKey, filterString);
            }
            if (status) {
                params.append('status', status);
            }
            if (currency) {
                params.append('currencyCode', currency);
            }
            if (dateRange[0]) {
                params.append('startDateFrom', dateRange[0].format('YYYY-MM-DD'));
            }
            if (dateRange[1]) {
                params.append('startDateTo', dateRange[1].format('YYYY-MM-DD'));
            }

            const headers = getLoginRequestHeader('application/json');

            return from(
                axios.get(`${SERVER_URL}/api/v1/brand/current/campaign`, {
                    params: params,
                    headers: headers,
                }),
            ).pipe(
                map((response) => getAwardedPromotionsSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, PromotionTypes.GET_AWARDED_PROMOTIONS_REQUEST)),
                ),
            );
        }),
    );

export const createPromotionEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.CREATE_FREE_ROUNDS_PROMOTION_REQUEST),
        mergeMap((action) => {
            const { payload } = action;

            const requestBody: any = {
                name: payload.campaign_name,
                gameIds: payload.game_name,
                startDate: payload.start_at,
                endDate: payload.end_at,
                timezone: payload.timezone,
                bet: payload.bet,
                currencyCode: payload.currency,
                betNumber: payload.number_of_free_rounds,
                eligiblePlayersNumber: payload.players,
                countries: payload.countries,
                expiresInDays: payload.expires_in_days,
                strategy: 'FIRST_N_PLAYERS',
                rulesUrl: payload.rules_url,
            };

            const headers = getLoginRequestHeader('application/json');

            const fromPromise = from(
                axios.post(`${SERVER_URL}/api/v1/brand/current/campaign`, requestBody, {
                    headers: headers,
                }),
            );

            return fromPromise.pipe(
                map((response) => createPromotionSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, PromotionTypes.CREATE_FREE_ROUNDS_PROMOTION_REQUEST)),
                ),
            );
        }),
    );

export const editPromotionEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.EDIT_PROMOTION_REQUEST),
        mergeMap((action) => {
            const { values } = action.payload;

            const requestBody: any = {
                name: values.campaign_name,
                gameIds: values.game_name,
                startDate: values.start_at,
                endDate: values.end_at,
                timezone: values.timezone,
                bet: values.bet,
                currencyCode: values.currency,
                betNumber: values.number_of_free_rounds,
                eligiblePlayersNumber: values.isFile ? null : values.players,
                countries: values.countries,
                expiresInDays: values.expires_in_days,
                strategy: values.isFile ? 'PLAYER_LIST' : 'FIRST_N_PLAYERS',
                rulesUrl: values.rules_url,
            };

            const headers = getLoginRequestHeader('application/json');

            const fromPromise = from(
                axios.put(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${action.payload.id}`,
                    requestBody,
                    {
                        headers: headers,
                    },
                ),
            );

            return fromPromise.pipe(
                map((response) => editPromotionSuccess(response.data)),
                catchError((error) => of(apiError(error, PromotionTypes.EDIT_PROMOTION_REQUEST))),
            );
        }),
    );

export const createPromotionWithCSVEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.CREATE_PROMOTION_WITH_CSV_REQUEST),
        mergeMap((action) => {
            const { payload } = action;

            const requestBody: any = {
                name: payload.campaign_name,
                gameIds: payload.game_name,
                startDate: payload.start_at,
                endDate: payload.end_at,
                timezone: payload.timezone,
                bet: payload.bet,
                currencyCode: payload.currency,
                betNumber: payload.number_of_free_rounds,
                countries: payload.countries,
                expiresInDays: payload.expires_in_days,
                strategy: 'PLAYER_LIST',
                rulesUrl: payload.rules_url,
            };

            const headers = getLoginRequestHeader('application/json');

            const fromPromise = from(
                axios.post(`${SERVER_URL}/api/v1/brand/current/campaign`, requestBody, { headers }),
            );

            return fromPromise.pipe(
                map((response) =>
                    createPromotionWithCSVSuccess({
                        ...response.data,
                        file: action.payload.file,
                    }),
                ),
                catchError((error) =>
                    of(apiError(error, PromotionTypes.CREATE_PROMOTION_WITH_CSV_REQUEST)),
                ),
            );
        }),
    );

export const editPromotionWithCSVEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.EDIT_PROMOTION_WITH_CSV_REQUEST),
        mergeMap((action) => {
            const { values } = action.payload;

            const requestBody: any = {
                name: values.campaign_name,
                gameIds: values.game_name,
                startDate: values.start_at,
                endDate: values.end_at,
                timezone: values.timezone,
                bet: values.bet,
                currencyCode: values.currency,
                betNumber: values.number_of_free_rounds,
                countries: values.countries,
                expiresInDays: values.expires_in_days,
                strategy: 'PLAYER_LIST',
                rulesUrl: values.rules_url,
            };

            const headers = getLoginRequestHeader('application/json');

            const fromPromise = from(
                axios.put(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${action.payload.id}`,
                    requestBody,
                    { headers },
                ),
            );

            return fromPromise.pipe(
                map((response) =>
                    editPromotionWithCSVSuccess({
                        ...response.data,
                        id: action.payload.id,
                        file: action.payload.values.file,
                    }),
                ),
                catchError((error) =>
                    of(apiError(error, PromotionTypes.EDIT_PROMOTION_WITH_CSV_REQUEST)),
                ),
            );
        }),
    );

export const uploadPromotionCSVEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(
            PromotionTypes.CREATE_PROMOTION_WITH_CSV_SUCCESS,
            PromotionTypes.EDIT_PROMOTION_WITH_CSV_SUCCESS,
        ),
        mergeMap((action) => {
            const headers = getLoginRequestHeader();

            const fromPromise = from(
                axios.post(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${action.payload.id}/participant-file`,
                    action.payload.file,
                    { headers },
                ),
            );

            return fromPromise.pipe(
                map((response) => uploadCSVSuccess(response.data)),
                catchError((error) => of(apiError(error, PromotionTypes.UPLOAD_CSV_REQUEST))),
            );
        }),
    );

export const getCampaignEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.GET_FREE_ROUNDS_CAMPAIGN_REQUEST),
        mergeMap(async (action) => {
            const {
                campaignId,
                paginationData,
                sortKey,
                sortOrder,
                playerIdSearchValue,
                playersStatus,
                currency,
            } = action.payload;

            const headers = getLoginRequestHeader('application/json');

            try {
                let finalCurrency = currency;

                const campaignDataResponse = await axios.get(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${campaignId}`,
                    { headers },
                );

                if (currency === 'user') {
                    finalCurrency = currencyUtils.getActiveCurrencyData().code;
                } else if (currency === 'campaign') {
                    finalCurrency = campaignDataResponse.data.currencyCode;
                }

                const summaryRequest = axios.get(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${campaignId}/summary`,
                    { headers, params: { currency: finalCurrency } },
                );

                const shouldRequestCsv = campaignDataResponse.data.strategy === 'PLAYER_LIST';
                const csvRequest = shouldRequestCsv
                    ? axios.get(
                          `${SERVER_URL}/api/v1/brand/current/campaign/${campaignId}/participant/csv`,
                          { headers },
                      )
                    : Promise.resolve({ data: null });

                const playersRequest = axios.get(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${campaignId}/participant`,
                    {
                        headers,
                        params: {
                            currency: finalCurrency,
                            'paging.pageNumber': paginationData.pageNumber,
                            'paging.pageSize': paginationData.pageSize,
                            'paging.sortBy': sortKey,
                            'paging.order': sortOrder,
                            ...(playerIdSearchValue ? { playerId: playerIdSearchValue } : {}),
                            ...(playersStatus !== 'all_statuses' ? { status: playersStatus } : {}),
                        },
                    },
                );

                const [summaryResponse, csvResponse, playersResponse] = await Promise.all([
                    summaryRequest,
                    csvRequest,
                    playersRequest,
                ]);

                return getCampaignSuccess({
                    campaignData: campaignDataResponse.data,
                    summary: summaryResponse.data,
                    players: playersResponse.data,
                    csv: csvResponse.data,
                });
            } catch (error) {
                return apiError(error, PromotionTypes.GET_FREE_ROUNDS_CAMPAIGN_REQUEST);
            }
        }),
    );

export const deletePromotionEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.DELETE_CAMPAIGN_REQUEST),
        mergeMap(async (action) => {
            const headers = getLoginRequestHeader('application/json');

            try {
                const response = await axios.delete(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${action.payload}`,
                    { headers },
                );
                return deleteCampaignSuccess(response.data);
            } catch (error) {
                return apiError(error, PromotionTypes.DELETE_CAMPAIGN_REQUEST);
            }
        }),
    );

export const terminatePromotionEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.TERMINATE_CAMPAIGN_REQUEST),
        mergeMap(async (action) => {
            const requestBody = {
                enabled: false,
                force: action.payload.force,
            };

            const headers = getLoginRequestHeader('application/json');

            try {
                const response = await axios.put(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${action.payload.id}/enabled`,
                    requestBody,
                    { headers },
                );
                return terminateCampaignSuccess(response.data);
            } catch (error) {
                return apiError(error, PromotionTypes.TERMINATE_CAMPAIGN_REQUEST);
            }
        }),
    );

export const getGamesByPromotionPriceEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.GET_GAMES_BY_PROMOTION_PRICE_REQUEST),
        mergeMap((action) => {
            const { price, currencyId } = action.payload;
            const headers = getLoginRequestHeader('application/json');

            return from(
                axios.get(
                    `${SERVER_URL}/games/awarded-feature?currencyId=${currencyId}&price=${price}`,
                    {
                        headers: headers,
                    },
                ),
            ).pipe(
                map((response) => getGamesByPromotionPriceSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, PromotionTypes.GET_GAMES_BY_PROMOTION_PRICE_REQUEST)),
                ),
            );
        }),
    );

export const cloneCampaignEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.CLONE_CAMPAIGN_REQUEST),
        mergeMap((action) => {
            const campaignId = action.payload;
            const headers = getLoginRequestHeader('application/json');

            const fetchCampaignData = axios.get(
                `${SERVER_URL}/api/v1/brand/current/campaign/${campaignId}`,
                { headers },
            );

            return from(fetchCampaignData).pipe(
                mergeMap((campaignDataResponse) => {
                    const shouldRequestCsv = campaignDataResponse.data.strategy === 'PLAYER_LIST';

                    const fetchCsv = shouldRequestCsv
                        ? axios.get(
                              `${SERVER_URL}/api/v1/brand/current/campaign/${campaignId}/participant/csv`,
                              { headers },
                          )
                        : Promise.resolve({ data: null });

                    return from(fetchCsv).pipe(
                        mergeMap((csvResponse) =>
                            of(
                                cloneCampaignSuccess({
                                    campaignData: campaignDataResponse.data,
                                    csv: csvResponse.data,
                                }),
                            ),
                        ),
                    );
                }),
                catchError((error) => of(apiError(error, PromotionTypes.CLONE_CAMPAIGN_REQUEST))),
            );
        }),
    );

export const createAwardedPromotionEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.CREATE_AWARDED_PROMOTION_REQUEST),
        mergeMap((action) => {
            const { payload } = action;

            const requestBody: any = {
                name: payload.campaign_name,
                gameIds: payload.gameIds,
                startDate: payload.start_at,
                endDate: payload.end_at,
                timezone: payload.timezone,
                bet: Number(payload.price),
                currencyCode: payload.currency,
                countries: countriesList,
                validForDays: Number(payload.valid_for),
                strategy: payload.strategy,
                betNumber: Number(payload.number_of_features),
                startEvent: payload.start_event,
                allowPostExpirationCompletion: payload.allowPostExpirationCompletion,
                type: 'AWARDED_FEATURE',
            };

            if (payload.strategy === 'FIRST_N_PLAYERS') {
                requestBody.eligiblePlayersNumber = payload.number_of_players;
            }

            if (payload.rules_url) {
                requestBody.rulesUrl = payload.rules_url;
            }

            const headers = getLoginRequestHeader('application/json');

            return from(
                axios.post(`${SERVER_URL}/api/v1/brand/current/campaign`, requestBody, { headers }),
            ).pipe(
                switchMap((response) => {
                    const campaignId = response.data.id;

                    if (payload.strategy === 'PLAYER_LIST') {
                        const csvFile = createBinaryCsvFile(payload.players);
                        const formData = new FormData();

                        formData.append('file', csvFile, 'participants.csv');

                        return from(
                            axios.post(
                                `${SERVER_URL}/api/v1/brand/current/campaign/${campaignId}/participant-file`,
                                formData,
                                {
                                    headers: {
                                        ...getLoginRequestHeader(),
                                    },
                                },
                            ),
                        ).pipe(
                            map(() => createAwardedPromotionSuccess(response.data)),
                            catchError((error) =>
                                of(
                                    apiError(
                                        error,
                                        PromotionTypes.CREATE_AWARDED_PROMOTION_REQUEST,
                                    ),
                                ),
                            ),
                        );
                    }

                    return of(createAwardedPromotionSuccess(response.data));
                }),
                catchError((error) =>
                    of(apiError(error, PromotionTypes.CREATE_AWARDED_PROMOTION_REQUEST)),
                ),
            );
        }),
    );

export const getAwardedCampaignEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.GET_AWARDED_CAMPAIGN_REQUEST),
        mergeMap((action) => {
            const { campaignId, currency } = action.payload;
            const headers = getLoginRequestHeader('application/json');

            return from(
                (async () => {
                    let finalCurrency = currency;

                    const campaignDataResponse = await axios.get(
                        `${SERVER_URL}/api/v1/brand/current/campaign/${campaignId}`,
                        { headers },
                    );

                    if (currency === 'user') {
                        finalCurrency = currencyUtils.getActiveCurrencyData().code;
                    } else if (currency === 'campaign') {
                        finalCurrency = campaignDataResponse.data.currencyCode;
                    }

                    const summaryResponse = await axios.get(
                        `${SERVER_URL}/api/v1/brand/current/campaign/${campaignId}/summary`,
                        { headers, params: { currency: finalCurrency } },
                    );

                    return getAwardedCampaignSuccess({
                        campaignData: campaignDataResponse.data,
                        summary: summaryResponse.data,
                    });
                })(),
            ).pipe(
                mergeMap((response) => of(response)),
                catchError((error) =>
                    of(apiError(error, PromotionTypes.GET_AWARDED_CAMPAIGN_REQUEST)),
                ),
            );
        }),
    );

export const getAwardedCampaignPlayersEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.GET_AWARDED_CAMPAIGN_PLAYERS_REQUEST),
        mergeMap((action) => {
            const {
                campaignId,
                paginationData,
                sortKey,
                sortOrder,
                playerIdSearchValue,
                playersStatus,
                currency,
            } = action.payload;

            const headers = getLoginRequestHeader('application/json');
            const playersConfig = {
                headers,
                params: {
                    currency,
                    'paging.pageNumber': paginationData.pageNumber,
                    'paging.pageSize': paginationData.pageSize,
                    'paging.sortBy': sortKey,
                    'paging.order': sortOrder,
                    ...(playerIdSearchValue ? { playerId: playerIdSearchValue } : {}),
                    ...(playersStatus !== 'all_statuses' ? { status: playersStatus } : {}),
                },
            };

            return from(
                axios.get(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${campaignId}/participant`,
                    playersConfig,
                ),
            ).pipe(
                map(({ data }) => getAwardedCampaignPlayersSuccess(data)),
                catchError((error) =>
                    of(apiError(error, PromotionTypes.GET_AWARDED_CAMPAIGN_PLAYERS_REQUEST)),
                ),
            );
        }),
    );

export const editAwardedPromotionEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.EDIT_AWARDED_CAMPAIGN_REQUEST),
        mergeMap((action) => {
            const { payload } = action;

            const requestBody: any = {
                name: payload.campaign_name,
                gameIds: payload.gameIds,
                startDate: payload.start_at,
                endDate: payload.end_at,
                timezone: payload.timezone,
                bet: payload.price,
                currencyCode: payload.currency,
                betNumber: payload.number_of_features,
                countries: countriesList,
                validForDays: payload.valid_for,
                strategy: payload.strategy,
                rulesUrl: payload.rules_url,
                startEvent: payload.start_event,
                allowPostExpirationCompletion: payload.allowPostExpirationCompletion,
                type: 'AWARDED_FEATURE',
            };

            if (payload.strategy === 'FIRST_N_PLAYERS') {
                requestBody.eligiblePlayersNumber = payload.number_of_players;
            }

            const headers = getLoginRequestHeader('application/json');

            const fromPromise = from(
                axios.put(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${action.payload.campaignId}`,
                    requestBody,
                    {
                        headers: headers,
                    },
                ),
            );

            return fromPromise.pipe(
                map((response) => editAwardedCampaignSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, PromotionTypes.EDIT_AWARDED_CAMPAIGN_REQUEST)),
                ),
            );
        }),
    );

export const importCsvFileEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.IMPORT_PLAYERS_CSV_REQUEST),
        mergeMap((action) => {
            const headers = {
                ...getLoginRequestHeader(),
                'Content-Type': 'multipart/form-data',
            };

            const formData = new FormData();
            formData.append('file', action.payload.file);

            return from(
                axios.post(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${action.payload.campaignId}/participant-file`,
                    formData,
                    { headers },
                ),
            ).pipe(
                map((response) => importPlayersCsvSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, PromotionTypes.IMPORT_PLAYERS_CSV_REQUEST)),
                ),
            );
        }),
    );

export const addPlayerToCampaignEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.ADD_PLAYER_TO_CAMPAIGN_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader();

            const fromPromise = from(
                axios.post(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${action.payload.campaignId}/participant`,
                    { playerId: action.payload.playerId },
                    { headers },
                ),
            );

            return fromPromise.pipe(
                map((response) => addPlayerToCampaignCsvSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, PromotionTypes.ADD_PLAYER_TO_CAMPAIGN_REQUEST)),
                ),
            );
        }),
    );

export const removePlayersFromCampaignEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.REMOVE_PLAYERS_FROM_CAMPAIGN_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader();

            const fromPromise = from(
                axios.post(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${action.payload.campaignId}/participant-removing`,
                    { playerIds: action.payload.playerIds },
                    { headers },
                ),
            );

            return fromPromise.pipe(
                map((response) => removePlayersFromCampaignSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, PromotionTypes.REMOVE_PLAYERS_FROM_CAMPAIGN_REQUEST)),
                ),
            );
        }),
    );
