import React, { ReactNode } from 'react';
import { ErrorMessage, Field, FieldProps } from 'formik';
import {
    Input,
    Select,
    DatePicker,
    Tooltip,
    Radio,
    Space,
    InputNumber,
    Checkbox,
    CheckboxProps,
} from 'antd';
import dayjs from 'dayjs';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { currencyUtils } from '../../../../utils';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

interface FormikFieldProps {
    name: string;
    category:
        | 'input'
        | 'select'
        | 'currencySelect'
        | 'datePicker'
        | 'checkbox'
        | 'radio'
        | 'strategy'
        | 'price';
    children?: ReactNode;
    type?: string;
    options?: any;
    label?: string;
    t?: (key: string) => string;
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    onChange?: Function;
}

interface CommonProps extends FieldProps {
    form: any;
    field: any;
    onChange?: (checked: boolean) => void;
}

const FormikInput = ({ field, ...props }: CommonProps) => (
    <Input size="large" {...field} {...props} />
);

const FormikSelect = ({
    field,
    form,
    options,
    placeholder,
    ...props
}: FieldProps & { options?: any; placeholder?: string }) => (
    <Select
        {...field}
        {...props}
        value={field.value}
        onChange={(value) => form.setFieldValue(field.name, value)}
        className="w-full"
        size="large"
        showSearch
        placeholder={placeholder}
    >
        {options ? (
            options.map((opt: any) => (
                <Select.Option key={opt.value} value={opt.value}>
                    {opt.label}
                </Select.Option>
            ))
        ) : (
            <></>
        )}
    </Select>
);

const CurrencySelect = ({
    field,
    form,
    options,
    placeholder,
    ...props
}: FieldProps & { options?: any; placeholder?: string }) => {
    const onChange = (value: string) => {
        const subunit = currencyUtils.getCurrencyDataByCode(value).subunit || 100;
        form.setFieldValue('subunit', subunit);
        form.setFieldValue('price', 0 / subunit);
        form.setFieldValue('currency', value);
    };

    return (
        <Select
            {...field}
            {...props}
            value={field.value}
            onChange={onChange}
            className="w-full"
            size="large"
            showSearch
            placeholder={placeholder}
        >
            {options ? (
                options.map((opt: any) => (
                    <Select.Option key={opt.value} value={opt.value}>
                        {opt.label}
                    </Select.Option>
                ))
            ) : (
                <></>
            )}
        </Select>
    );
};

export const FormikDatePicker = ({ field, form, ...props }: CommonProps) => {
    const handleChange = (value: any) => {
        if (!value) {
            form.setFieldValue(field.name, null);
            return;
        }

        const selectedTz = form.values.timezone || 'UTC';
        const naiveDate = dayjs(value).format('YYYY-MM-DD HH:mm');
        const storedDate = dayjs.tz(naiveDate, 'YYYY-MM-DD HH:mm', selectedTz);

        form.setFieldValue(field.name, storedDate);
    };

    return (
        <DatePicker
            size="large"
            className="w-full"
            id={field.name}
            name={field.name}
            {...props}
            defaultValue={field.value ? dayjs(field.value) : undefined}
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            onChange={handleChange}
            onBlur={() => form.setFieldTouched(field.name, true)}
            showNow={false}
            allowClear={true}
        />
    );
};

const FormikCheckbox = ({ field, form, ...props }: FieldProps) => {
    const onChange: CheckboxProps['onChange'] = (e) => {
        form.setFieldValue(field.name, e.target.checked);
    };

    return (
        <div className="flex pt-5">
            <Checkbox className="mr-2" {...props} checked={field.value} onChange={onChange} />
            Allow completion after expiration
        </div>
    );
};

const FormikRadio = ({ field, form, options }: FieldProps & { options?: any }) => (
    <Radio.Group
        className="mt-4"
        onChange={(value) => form.setFieldValue(field.name, value)}
        value={field.value}
    >
        <Space direction="vertical">
            {options ? (
                options.map((opt: any) => (
                    <Radio key={opt.value} value={opt.value}>
                        {opt.label}
                    </Radio>
                ))
            ) : (
                <></>
            )}
        </Space>
    </Radio.Group>
);

const CampaignPriceField = ({ field, form, ...props }: FieldProps) => {
    const { subunit } = form.values;
    const getDecimalPlaces = () => {
        return subunit > 1 ? Math.log10(subunit) : 0;
    };

    const formatter = (value: string | number | undefined) => {
        if (!value) return '0';

        const numericValue = Number(value) / subunit;
        return numericValue.toFixed(getDecimalPlaces());
    };

    const parser = (value: string | undefined) => {
        if (!value) return 0;

        const numericString = value.replace(/\D/g, '');

        return parseInt(numericString, 10) || 0;
    };

    return (
        <InputNumber
            className="w-full"
            size="large"
            min={0}
            {...props}
            value={field.value}
            formatter={formatter}
            parser={parser}
            onChange={(value) => form.setFieldValue(field.name, value)}
            maxLength={10}
        />
    );
};

const CampaignStrategyField = ({ field, form }: FieldProps) => {
    return (
        <Radio.Group
            className="mt-4"
            onChange={(value) => {
                form.setFieldValue(field.name, value.target.value);
            }}
            value={field.value}
        >
            <Space direction="vertical">
                <Radio value="FIRST_N_PLAYERS">
                    <span className="mr-1">First</span>
                    <InputNumber
                        defaultValue={form.values.number_of_players}
                        size="small"
                        onChange={(value) => form.setFieldValue('number_of_players', value)}
                        name="number_of_players"
                        className="border rounded px-2 py-1 w-18"
                        disabled={form.values.strategy !== 'FIRST_N_PLAYERS'}
                    />
                    <span className="ml-1">players</span>
                </Radio>
                <Radio value="PLAYER_LIST">
                    <span>Player List</span>
                </Radio>
            </Space>
        </Radio.Group>
    );
};

const categoryToComponentMap: Record<string, any> = {
    input: FormikInput,
    select: FormikSelect,
    currencySelect: CurrencySelect,
    datePicker: FormikDatePicker,
    checkbox: FormikCheckbox,
    radio: FormikRadio,
    strategy: CampaignStrategyField,
    price: CampaignPriceField,
};

const FormikField: React.FC<FormikFieldProps> = ({
    name,
    category,
    type,
    options,
    label,
    t,
    className,
    placeholder,
    disabled,
}) => {
    const renderTooltip = () => {
        if (name === 'expires_in_days') {
            return t && t('expires_in_tooltip');
        }
        if (name === 'rules_url') {
            return t && t('rules_url_tooltip');
        }
        return null;
    };

    const Component = categoryToComponentMap[category];

    return (
        <div className={className}>
            <label className="" htmlFor={name}>
                {renderTooltip() && (
                    <Tooltip title={renderTooltip()}>
                        <QuestionCircleOutlined style={{ marginRight: '3px' }} />
                    </Tooltip>
                )}
                {label ? label + ':' : ''}
            </label>
            <div className="">
                <Field name={name} type={type} options={options}>
                    {({ field, form }: FieldProps) => (
                        <Component
                            field={field}
                            form={form}
                            options={options}
                            placeholder={placeholder}
                            disabled={disabled}
                            {...field}
                        />
                    )}
                </Field>
                <div className="relative">
                    <ErrorMessage className="text-red-600 absolute" name={name} component="div" />
                </div>
            </div>
        </div>
    );
};

export default FormikField;
