export const UnfinishedRoundsJobTypes = {
    UNFINISHED_ROUNDS_JOBS_REQUEST: 'UNFINISHED_ROUNDS_JOBS_REQUEST',
    UNFINISHED_ROUNDS_JOBS_SUCCESS: 'UNFINISHED_ROUNDS_JOBS_SUCCESS',
    UNFINISHED_ROUNDS_JOBS_FAILED: 'UNFINISHED_ROUNDS_JOBS_FAILED',
    RUN_JOB_MANUALLY_REQUEST: 'RUN_JOB_MANUALLY_REQUEST',
    RUN_JOB_MANUALLY_SUCCESS: 'RUN_JOB_MANUALLY_SUCCESS',
    RUN_JOB_MANUALLY_FAILED: 'RUN_JOB_MANUALLY_FAILED',
};

export const getUnfinishedRoundsJobs = (payload?: any) => ({
    type: UnfinishedRoundsJobTypes.UNFINISHED_ROUNDS_JOBS_REQUEST,
    payload,
});
export const getUnfinishedRoundsJobsSuccess = (payload?: any) => ({
    type: UnfinishedRoundsJobTypes.UNFINISHED_ROUNDS_JOBS_SUCCESS,
    payload,
});

