import { createSelector } from 'reselect';
import { dataFormatterUtils } from '../../../utils';

const getPromotions = (state: any) => state.promotionsReducer.allFreeRoundCampaigns;
const getAwardedPromotions = (state: any) => state.promotionsReducer.allAwardedCampaigns;

const getTotalPromotionsAmount = (state: any) => state.promotionsReducer.allFreeRoundCampaignsTotal;
const getTotalAwardedPromotionsAmount = (state: any) =>
    state.promotionsReducer.allAwardedCampaignsTotal;

const getCampaign = (state: any) => state.promotionsReducer.freeRoundsCampaignData;
const getCampaignSummary = (state: any) => state.promotionsReducer.freeRoundsCampaignSummary;
const getCampaignPlayers = (state: any) => state.promotionsReducer.freeRoundsCampaignPlayers;
const getCampaignPlayersCSV = (state: any) => state.promotionsReducer.csv;

const getCampaignPlayersTotal = (state: any) =>
    state.promotionsReducer.freeRoundsCampaignPlayersTotal;
const getAwardedCampaign = (state: any) => state.promotionsReducer.awardedCampaignData;
const getAwardedCampaignSummary = (state: any) => state.promotionsReducer.awardedCampaignSummary;
const getPromotionsLoadingState = (state: any) => state.promotionsReducer.isLoading;
const getPromotionsError = (state: any) => state.promotionsReducer.error;
const getShouldUpdateData = (state: any) => state.promotionsReducer.shouldUpdateData;
const getGamesByPrice = (state: any) => state.promotionsReducer.gamesByPrice;
const getGamesByPriceAreLoading = (state: any) => state.promotionsReducer.gamesByPriceAreLoading;
const getClonedCampaign = (state: any) => state.promotionsReducer.clonedCampaign;
const getClonedCampaignCsv = (state: any) => state.promotionsReducer.clonedCampaignCsv;
const getIsClonedCampaignLoading = (state: any) => state.promotionsReducer.isClonedCampaignLoading;
const getCampaignHasBeenCreated = (state: any) => state.promotionsReducer.campaignHasBeenCreated;
const getCreatePromotionLoading = (state: any) => state.promotionsReducer.getCreatePromotionLoading;
const getAwardedCampaignPlayers = (state: any) => state.promotionsReducer.awardedCampaignPlayers;
const getAwardedCampaignPlayersTotal = (state: any) =>
    state.promotionsReducer.awardedCampaignPlayersTotal;

export const getPromotionsData = createSelector(
    getPromotions,
    (state) => state && dataFormatterUtils.getFormattedData(state),
);
export const getTotalPromotionsAmountData = createSelector(
    getTotalPromotionsAmount,
    (state) => state,
);
export const getAwardedPromotionsData = createSelector(
    getAwardedPromotions,
    (state) => state && dataFormatterUtils.getFormattedData(state),
);
export const getTotalAwardedPromotionsAmountData = createSelector(
    getTotalAwardedPromotionsAmount,
    (state) => state,
);
export const getPromotionsLoadingStateData = createSelector(
    getPromotionsLoadingState,
    (state) => state,
);
export const getPromotionsErrorData = createSelector(getPromotionsError, (state) => state);
export const shouldUpdateData = createSelector(getShouldUpdateData, (state) => state);
export const getCampaignData = createSelector(getCampaign, (state) => state);
export const getCampaignSummaryData = createSelector(getCampaignSummary, (state) => state);
export const getCampaignPlayersTotalData = createSelector(
    getCampaignPlayersTotal,
    (state) => state,
);
export const getCampaignPlayersData = createSelector(getCampaignPlayers, (state) => state);
export const getCampaignPlayersDataCSV = createSelector(getCampaignPlayersCSV, (state) => state);
export const getGamesByPriceData = createSelector(getGamesByPrice, (state) => state);
export const getGamesByPriceAreLoadingData = createSelector(
    getGamesByPriceAreLoading,
    (state) => state,
);
export const getClonedCampaignData = createSelector(getClonedCampaign, (state) => state);
export const getClonedCampaignCsvData = createSelector(getClonedCampaignCsv, (state) => state);
export const getIsClonedCampaignLoadingData = createSelector(
    getIsClonedCampaignLoading,
    (state) => state,
);
export const campaignHasBeenCreatedData = createSelector(
    getCampaignHasBeenCreated,
    (state) => state,
);
export const createPromotionLoadingData = createSelector(
    getCreatePromotionLoading,
    (state) => state,
);
export const getAwardedCampaignData = createSelector(getAwardedCampaign, (state) => state);
export const getAwardedCampaignSummaryData = createSelector(
    getAwardedCampaignSummary,
    (state) => state,
);
export const getAwardedCampaignPlayersTotalData = createSelector(
    getAwardedCampaignPlayersTotal,
    (state) => state,
);
export const getAwardedCampaignPlayersData = createSelector(
    getAwardedCampaignPlayers,
    (state) => state,
);
