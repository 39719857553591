export const PromotionTypes = {
    GET_FREE_ROUNDS_PROMOTIONS_REQUEST: 'GET_FREE_ROUNDS_PROMOTIONS_REQUEST',
    GET_FREE_ROUNDS_PROMOTIONS_SUCCESS: 'GET_FREE_ROUNDS_PROMOTIONS_SUCCESS',
    GET_FREE_ROUNDS_PROMOTIONS_FAILED: 'GET_FREE_ROUNDS_PROMOTIONS_FAILED',
    CREATE_FREE_ROUNDS_PROMOTION_REQUEST: 'CREATE_FREE_ROUNDS_PROMOTION_REQUEST',
    CREATE_FREE_ROUNDS_PROMOTION_SUCCESS: 'CREATE_FREE_ROUNDS_PROMOTION_SUCCESS',
    CREATE_FREE_ROUNDS_PROMOTION_FAILED: 'CREATE_FREE_ROUNDS_PROMOTION_FAILED',
    EDIT_PROMOTION_REQUEST: 'EDIT_PROMOTION_REQUEST',
    EDIT_PROMOTION_SUCCESS: 'EDIT_PROMOTION_SUCCESS',
    EDIT_PROMOTION_FAILED: 'EDIT_PROMOTION_FAILED',
    CREATE_PROMOTION_WITH_CSV_REQUEST: 'CREATE_PROMOTION_WITH_CSV_REQUEST',
    CREATE_PROMOTION_WITH_CSV_SUCCESS: 'CREATE_PROMOTION_WITH_CSV_SUCCESS',
    CREATE_PROMOTION_WITH_CSV_FAILED: 'CREATE_PROMOTION_WITH_CSV_FAILED',
    EDIT_PROMOTION_WITH_CSV_REQUEST: 'EDIT_PROMOTION_WITH_CSV_REQUEST',
    EDIT_PROMOTION_WITH_CSV_SUCCESS: 'EDIT_PROMOTION_WITH_CSV_SUCCESS',
    EDIT_PROMOTION_WITH_CSV_FAILED: 'EDIT_PROMOTION_WITH_CSV_FAILED',
    UPLOAD_CSV_REQUEST: 'UPLOAD_CSV_REQUEST',
    UPLOAD_CSV_SUCCESS: 'UPLOAD_CSV_SUCCESS',
    UPLOAD_CSV_FAILED: 'UPLOAD_CSV_FAILED',
    GET_FREE_ROUNDS_CAMPAIGN_REQUEST: 'GET_FREE_ROUNDS_CAMPAIGN_REQUEST',
    GET_FREE_ROUNDS_CAMPAIGN_SUCCESS: 'GET_FREE_ROUNDS_CAMPAIGN_SUCCESS',
    GET_FREE_ROUNDS_CAMPAIGN_FAILED: 'GET_FREE_ROUNDS_CAMPAIGN_FAILED',
    DELETE_CAMPAIGN_REQUEST: 'DELETE_CAMPAIGN_REQUEST',
    DELETE_CAMPAIGN_SUCCESS: 'DELETE_CAMPAIGN_SUCCESS',
    DELETE_CAMPAIGN_FAILED: 'DELETE_CAMPAIGN_FAILED',
    TERMINATE_CAMPAIGN_REQUEST: 'TERMINATE_CAMPAIGN_REQUEST',
    TERMINATE_CAMPAIGN_SUCCESS: 'TERMINATE_CAMPAIGN_SUCCESS',
    TERMINATE_CAMPAIGN_FAILED: 'TERMINATE_CAMPAIGN_FAILED',
    CLEAR_CAMPAIGN_DATA_REQUEST: 'CLEAR_CAMPAIGN_DATA_REQUEST',
    GET_AWARDED_PROMOTIONS_REQUEST: 'GET_AWARDED_PROMOTIONS_REQUEST',
    GET_AWARDED_PROMOTIONS_SUCCESS: 'GET_AWARDED_PROMOTIONS_SUCCESS',
    GET_AWARDED_PROMOTIONS_FAILED: 'GET_AWARDED_PROMOTIONS_SUCCESS_FAILED',
    GET_GAMES_BY_PROMOTION_PRICE_REQUEST: 'GET_GAMES_BY_PROMOTION_PRICE_REQUEST',
    GET_GAMES_BY_PROMOTION_PRICE_SUCCESS: 'GET_GAMES_BY_PROMOTION_PRICE_SUCCESS',
    GET_GAMES_BY_PROMOTION_PRICE_FAILED: 'GET_GAMES_BY_PROMOTION_PRICE_FAILED',
    CLONE_CAMPAIGN_REQUEST: 'CLONE_CAMPAIGN_REQUEST',
    CLONE_CAMPAIGN_SUCCESS: 'CLONE_CAMPAIGN_SUCCESS',
    CLONE_CAMPAIGN_FAILED: 'CLONE_CAMPAIGN_FAILED',
    CREATE_AWARDED_PROMOTION_REQUEST: 'CREATE_AWARDED_PROMOTION_REQUEST',
    CREATE_AWARDED_PROMOTION_SUCCESS: 'CREATE_AWARDED_PROMOTION_SUCCESS',
    CREATE_AWARDED_PROMOTION_FAILED: 'CREATE_AWARDED_PROMOTION_FAILED',
    RESET_NEW_PROMOTION: 'RESET_NEW_PROMOTION',
    GET_AWARDED_CAMPAIGN_REQUEST: 'GET_AWARDED_CAMPAIGN_REQUEST',
    GET_AWARDED_CAMPAIGN_SUCCESS: 'GET_AWARDED_CAMPAIGN_SUCCESS',
    GET_AWARDED_CAMPAIGN_FAILED: 'GET_AWARDED_CAMPAIGN_FAILED',
    GET_AWARDED_CAMPAIGN_PLAYERS_REQUEST: 'GET_AWARDED_CAMPAIGN_PLAYERS_REQUEST',
    GET_AWARDED_CAMPAIGN_PLAYERS_SUCCESS: 'GET_AWARDED_CAMPAIGN_PLAYERS_SUCCESS',
    GET_AWARDED_CAMPAIGN_PLAYERS_FAILED: 'GET_AWARDED_CAMPAIGN_PLAYERS_FAILED',
    EDIT_AWARDED_CAMPAIGN_REQUEST: 'EDIT_AWARDED_CAMPAIGN_REQUEST',
    EDIT_AWARDED_CAMPAIGN_SUCCESS: 'EDIT_AWARDED_CAMPAIGN_SUCCESS',
    EDIT_AWARDED_CAMPAIGN_FAILED: 'EDIT_AWARDED_CAMPAIGN_FAILED',
    IMPORT_PLAYERS_CSV_REQUEST: 'IMPORT_PLAYERS_CSV_REQUEST',
    IMPORT_PLAYERS_CSV_SUCCESS: 'IMPORT_PLAYERS_CSV_SUCCESS',
    IMPORT_PLAYERS_CSV_FAILED: 'IMPORT_PLAYERS_CSV_FAILED',
    ADD_PLAYER_TO_CAMPAIGN_REQUEST: 'ADD_PLAYER_TO_CAMPAIGN_REQUEST',
    ADD_PLAYER_TO_CAMPAIGN_SUCCESS: 'ADD_PLAYER_TO_CAMPAIGN_SUCCESS',
    ADD_PLAYER_TO_CAMPAIGN_FAILED: 'ADD_PLAYER_TO_CAMPAIGN_FAILED',
    REMOVE_PLAYERS_FROM_CAMPAIGN_REQUEST: 'REMOVE_PLAYERS_FROM_CAMPAIGN_REQUEST',
    REMOVE_PLAYERS_FROM_CAMPAIGN_SUCCESS: 'REMOVE_PLAYERS_FROM_CAMPAIGN_SUCCESS',
    REMOVE_PLAYERS_FROM_CAMPAIGN_FAILED: 'REMOVE_PLAYERS_FROM_CAMPAIGN_FAILED',
};

export const getPromotions = (payload?: any) => ({
    type: PromotionTypes.GET_FREE_ROUNDS_PROMOTIONS_REQUEST,
    payload,
});
export const getPromotionsSuccess = (payload?: any) => ({
    type: PromotionTypes.GET_FREE_ROUNDS_PROMOTIONS_SUCCESS,
    payload,
});
export const createPromotion = (payload?: any) => ({
    type: PromotionTypes.CREATE_FREE_ROUNDS_PROMOTION_REQUEST,
    payload,
});
export const createPromotionSuccess = (payload?: any) => ({
    type: PromotionTypes.CREATE_FREE_ROUNDS_PROMOTION_SUCCESS,
    payload,
});
export const editPromotion = (payload?: any) => ({
    type: PromotionTypes.EDIT_PROMOTION_REQUEST,
    payload,
});
export const editPromotionSuccess = (payload?: any) => ({
    type: PromotionTypes.EDIT_PROMOTION_SUCCESS,
    payload,
});
export const createPromotionWithCSV = (payload?: any) => ({
    type: PromotionTypes.CREATE_PROMOTION_WITH_CSV_REQUEST,
    payload,
});
export const createPromotionWithCSVSuccess = (payload?: any) => ({
    type: PromotionTypes.CREATE_PROMOTION_WITH_CSV_SUCCESS,
    payload,
});
export const editPromotionWithCSV = (payload?: any) => ({
    type: PromotionTypes.EDIT_PROMOTION_WITH_CSV_REQUEST,
    payload,
});
export const editPromotionWithCSVSuccess = (payload?: any) => ({
    type: PromotionTypes.EDIT_PROMOTION_WITH_CSV_SUCCESS,
    payload,
});
export const uploadCSV = (payload?: any) => ({
    type: PromotionTypes.UPLOAD_CSV_REQUEST,
    payload,
});
export const uploadCSVSuccess = (payload?: any) => ({
    type: PromotionTypes.UPLOAD_CSV_SUCCESS,
    payload,
});
export const getCampaign = (payload?: any) => ({
    type: PromotionTypes.GET_FREE_ROUNDS_CAMPAIGN_REQUEST,
    payload,
});
export const getCampaignSuccess = (payload?: any) => ({
    type: PromotionTypes.GET_FREE_ROUNDS_CAMPAIGN_SUCCESS,
    payload,
});
export const deleteCampaign = (payload?: any) => ({
    type: PromotionTypes.DELETE_CAMPAIGN_REQUEST,
    payload,
});
export const clearCampaignData = (payload?: any) => ({
    type: PromotionTypes.CLEAR_CAMPAIGN_DATA_REQUEST,
    payload,
});
export const deleteCampaignSuccess = (payload?: any) => ({
    type: PromotionTypes.DELETE_CAMPAIGN_SUCCESS,
    payload,
});
export const terminateCampaign = (payload?: any) => ({
    type: PromotionTypes.TERMINATE_CAMPAIGN_REQUEST,
    payload,
});
export const terminateCampaignSuccess = (payload?: any) => ({
    type: PromotionTypes.TERMINATE_CAMPAIGN_SUCCESS,
    payload,
});
export const getAwardedPromotions = (payload?: any) => ({
    type: PromotionTypes.GET_AWARDED_PROMOTIONS_REQUEST,
    payload,
});
export const getAwardedPromotionsSuccess = (payload?: any) => ({
    type: PromotionTypes.GET_AWARDED_PROMOTIONS_SUCCESS,
    payload,
});
export const getGamesByPromotionPrice = (payload?: any) => ({
    type: PromotionTypes.GET_GAMES_BY_PROMOTION_PRICE_REQUEST,
    payload,
});
export const getGamesByPromotionPriceSuccess = (payload?: any) => ({
    type: PromotionTypes.GET_GAMES_BY_PROMOTION_PRICE_SUCCESS,
    payload,
});
export const cloneCampaign = (payload?: any) => ({
    type: PromotionTypes.CLONE_CAMPAIGN_REQUEST,
    payload,
});
export const cloneCampaignSuccess = (payload?: any) => ({
    type: PromotionTypes.CLONE_CAMPAIGN_SUCCESS,
    payload,
});
export const createAwardedPromotion = (payload?: any) => ({
    type: PromotionTypes.CREATE_AWARDED_PROMOTION_REQUEST,
    payload,
});
export const createAwardedPromotionSuccess = (payload?: any) => ({
    type: PromotionTypes.CREATE_AWARDED_PROMOTION_SUCCESS,
    payload,
});
export const resetNewPromotion = (payload?: any) => ({
    type: PromotionTypes.RESET_NEW_PROMOTION,
    payload,
});
export const getAwardedCampaign = (payload?: any) => ({
    type: PromotionTypes.GET_AWARDED_CAMPAIGN_REQUEST,
    payload,
});
export const getAwardedCampaignSuccess = (payload?: any) => ({
    type: PromotionTypes.GET_AWARDED_CAMPAIGN_SUCCESS,
    payload,
});
export const getAwardedCampaignPlayers = (payload?: any) => ({
    type: PromotionTypes.GET_AWARDED_CAMPAIGN_PLAYERS_REQUEST,
    payload,
});
export const getAwardedCampaignPlayersSuccess = (payload?: any) => ({
    type: PromotionTypes.GET_AWARDED_CAMPAIGN_PLAYERS_SUCCESS,
    payload,
});
export const editAwardedCampaign = (payload?: any) => ({
    type: PromotionTypes.EDIT_AWARDED_CAMPAIGN_REQUEST,
    payload,
});
export const editAwardedCampaignSuccess = (payload?: any) => ({
    type: PromotionTypes.EDIT_AWARDED_CAMPAIGN_SUCCESS,
    payload,
});
export const importPlayersCsv = (payload?: any) => ({
    type: PromotionTypes.IMPORT_PLAYERS_CSV_REQUEST,
    payload,
});
export const importPlayersCsvSuccess = (payload?: any) => ({
    type: PromotionTypes.IMPORT_PLAYERS_CSV_SUCCESS,
    payload,
});
export const addPlayerToCampaign = (payload?: any) => ({
    type: PromotionTypes.ADD_PLAYER_TO_CAMPAIGN_REQUEST,
    payload,
});
export const addPlayerToCampaignCsvSuccess = (payload?: any) => ({
    type: PromotionTypes.ADD_PLAYER_TO_CAMPAIGN_SUCCESS,
    payload,
});
export const removePlayersFromCampaign = (payload?: any) => ({
    type: PromotionTypes.REMOVE_PLAYERS_FROM_CAMPAIGN_REQUEST,
    payload,
});
export const removePlayersFromCampaignSuccess = (payload?: any) => ({
    type: PromotionTypes.REMOVE_PLAYERS_FROM_CAMPAIGN_SUCCESS,
    payload,
});
